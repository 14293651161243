.rf-original-box .w-checkbox {
	 display: block;
	 margin-bottom: 5px;
	 padding-left: 20px;
}
 .rf-original-box .w-checkbox::before {
	 content: ' ';
	 display: table;
	 -ms-grid-column-span: 1;
	 grid-column-end: 2;
	 -ms-grid-column: 1;
	 grid-column-start: 1;
	 -ms-grid-row-span: 1;
	 grid-row-end: 2;
	 -ms-grid-row: 1;
	 grid-row-start: 1;
}
 .rf-original-box .w-checkbox::after {
	 content: ' ';
	 display: table;
	 -ms-grid-column-span: 1;
	 grid-column-end: 2;
	 -ms-grid-column: 1;
	 grid-column-start: 1;
	 -ms-grid-row-span: 1;
	 grid-row-end: 2;
	 -ms-grid-row: 1;
	 grid-row-start: 1;
	 clear: both;
}
 .rf-original-box .w-checkbox-input {
	 float: left;
	 margin-bottom: 0px;
	 margin-left: -20px;
	 margin-right: 0px;
	 margin-top: 4px;
	 line-height: normal;
}
 .rf-original-box .w-checkbox-input--inputType-custom {
	 border-top-width: 1px;
	 border-bottom-width: 1px;
	 border-left-width: 1px;
	 border-right-width: 1px;
	 border-top-color: #ccc;
	 border-bottom-color: #ccc;
	 border-left-color: #ccc;
	 border-right-color: #ccc;
	 border-top-style: solid;
	 border-bottom-style: solid;
	 border-left-style: solid;
	 border-right-style: solid;
	 width: 12px;
	 height: 12px;
	 border-bottom-left-radius: 2px;
	 border-bottom-right-radius: 2px;
	 border-top-left-radius: 2px;
	 border-top-right-radius: 2px;
}
 .rf-original-box .w-checkbox-input--inputType-custom.w--redirected-checked {
	 background-color: #3898ec;
	 border-top-color: #3898ec;
	 border-bottom-color: #3898ec;
	 border-left-color: #3898ec;
	 border-right-color: #3898ec;
	 background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
	 background-position: 50% 50%;
	 background-size: cover;
	 background-repeat: no-repeat;
}
 .rf-original-box .w-checkbox-input--inputType-custom.w--redirected-focus {
	 box-shadow: 0px 0px 3px 1px #3898ec;
}
 .rf-original-box .w-pagination-wrapper {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-flex-wrap: wrap;
	 -ms-flex-wrap: wrap;
	 flex-wrap: wrap;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
}
 .rf-original-box .w-pagination-previous {
	 display: block;
	 color: #333;
	 font-size: 14px;
	 margin-left: 10px;
	 margin-right: 10px;
	 padding-top: 9px;
	 padding-bottom: 9px;
	 padding-right: 20px;
	 padding-left: 20px;
	 background-color: #fafafa;
	 border-top-width: 1px;
	 border-right-width: 1px;
	 border-bottom-width: 1px;
	 border-left-width: 1px;
	 border-top-color: #ccc;
	 border-right-color: #ccc;
	 border-bottom-color: #ccc;
	 border-left-color: #ccc;
	 border-top-style: solid;
	 border-right-style: solid;
	 border-bottom-style: solid;
	 border-left-style: solid;
	 border-top-right-radius: 2px;
	 border-bottom-right-radius: 2px;
	 border-bottom-left-radius: 2px;
	 border-top-left-radius: 2px;
}
 .rf-original-box .w-pagination-previous-icon {
	 margin-right: 4px;
}
 .rf-original-box .w-pagination-next {
	 display: block;
	 color: #333;
	 font-size: 14px;
	 margin-left: 10px;
	 margin-right: 10px;
	 padding-top: 9px;
	 padding-bottom: 9px;
	 padding-right: 20px;
	 padding-left: 20px;
	 background-color: #fafafa;
	 border-top-width: 1px;
	 border-right-width: 1px;
	 border-bottom-width: 1px;
	 border-left-width: 1px;
	 border-top-color: #ccc;
	 border-right-color: #ccc;
	 border-bottom-color: #ccc;
	 border-left-color: #ccc;
	 border-top-style: solid;
	 border-right-style: solid;
	 border-bottom-style: solid;
	 border-left-style: solid;
	 border-top-right-radius: 2px;
	 border-bottom-right-radius: 2px;
	 border-bottom-left-radius: 2px;
	 border-top-left-radius: 2px;
}
 .rf-original-box .w-pagination-next-icon {
	 margin-left: 4px;
}
 .rf-original-box .w-page-count {
	 width: 100%;
	 text-align: center;
	 margin-top: 20px;
}
 .rf-original-box body {
	 font-family: Montserrat, sans-serif;
	 color: #333;
	 font-size: 14px;
	 line-height: 20px;
}
 .rf-original-box h1 {
	 margin-top: 0px;
	 margin-bottom: 0px;
	 font-family: 'Cormorant Garamond', sans-serif;
	 font-size: 80px;
	 line-height: 97px;
	 font-weight: 700;
}
 .rf-original-box h2 {
	 margin-top: 0px;
	 margin-bottom: 0px;
	 font-family: 'Cormorant Garamond', sans-serif;
	 font-size: 56px;
	 line-height: 68px;
	 font-weight: 700;
}
 .rf-original-box h3 {
	 margin-top: 0px;
	 margin-bottom: 0px;
	 font-size: 24px;
	 line-height: 30px;
	 font-weight: 700;
}
 .rf-original-box h4 {
	 margin-top: 0px;
	 margin-bottom: 0px;
	 font-size: 18px;
	 line-height: 24px;
	 font-weight: 700;
}
 .rf-original-box h5 {
	 margin-top: 0px;
	 margin-bottom: 0px;
	 font-size: 14px;
	 line-height: 20px;
	 font-weight: 700;
}
 .rf-original-box h6 {
	 margin-top: 0px;
	 margin-bottom: 0px;
	 font-size: 12px;
	 line-height: 18px;
	 font-weight: 700;
}
 .rf-original-box p {
	 margin-bottom: 0px;
}
 .rf-original-box a {
	 text-decoration: underline;
}
 .rf-original-box ul {
	 margin-top: 0px;
	 margin-bottom: 10px;
	 padding-left: 40px;
}
 .rf-original-box ol {
	 margin-top: 0px;
	 margin-bottom: 10px;
	 padding-left: 40px;
}
 .rf-original-box img {
	 display: inline-block;
	 max-width: 100%;
}
 .rf-original-box strong {
	 font-weight: bold;
}
 .rf-original-box em {
	 font-style: italic;
}
 .rf-original-box blockquote {
	 margin-bottom: 10px;
	 padding: 10px 20px;
	 border-left: 5px solid #e2e2e2;
	 font-size: 18px;
	 line-height: 22px;
}
 .rf-original-box figure {
	 margin-top: 40px;
	 margin-bottom: 40px;
}
 .rf-original-box figcaption {
	 margin-top: 5px;
	 text-align: center;
}
 .rf-original-box .section {
	 padding-right: 60px;
	 padding-left: 60px;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .section.blue-bg {
	 background-color: #04243a;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .section.blue-bg.img-bg-on-blue {
	 display: block;
	 background-image: url('images/green.png');
	 background-position: 0px 0px;
	 background-size: auto;
}
 .rf-original-box .section.blue-bg.img-bg-on-blue.hidden {
	 display: none;
}
 .rf-original-box .section.mint-bg {
	 background-color: #d6e2dd;
}
 .rf-original-box .section.green-bg {
	 overflow: hidden;
	 padding-right: 0px;
	 padding-left: 0px;
	 background-color: #27806e;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .section.image-bg {
	 display: block;
	 overflow: visible;
	 background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	 background-position: 0px 0px;
	 background-size: auto;
	 color: #04243a;
}
 .rf-original-box .section.image-bg.hidden {
	 display: none;
}
 .rf-original-box .section.image-bg-green {
	 display: block;
	 overflow: visible;
	 background-color: #d6e2dd;
	 background-image: url('images/green.png');
	 background-position: 0px 0px;
	 background-size: auto;
	 color: #04243a;
}
 .rf-original-box .section.image-bg-green.nophoto {
	 background-image: none;
}
 .rf-original-box .section.image-bg-blue {
	 display: block;
	 overflow: visible;
	/*background-image: url('../images/Blue-1.png');
	*/
	 background-position: 0px 0px;
	 background-size: auto;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
}
 .rf-original-box .section.image-bg-green {
	 display: block;
	 overflow: visible;
	/*background-image: url('../images/Blue.png');
	*/
	 background-position: 0px 0px;
	 background-size: auto;
	 color: #04243a;
}
 .rf-original-box .section.section--blog-post {
	 margin-top: 68px;
	 background-color: #27806e;
}
 .rf-original-box .section.section--blogs-page {
	 margin-top: 120px;
}
 .rf-original-box .section.section--blogs-section {
	 margin-top: 120px;
	 margin-bottom: 120px;
}
 .rf-original-box .section.section--blogs-section.phone-slider {
	 display: block;
}
 .rf-original-box .section.section--blogs-section.phone-slider.home-blog-hidden {
	 display: none;
}
 .rf-original-box .container {
	 position: relative;
	 display: block;
	 max-width: 940px;
	 margin-right: auto;
	 margin-left: auto;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .container.hero {
	 position: relative;
	 max-width: none;
	 padding-right: 20px;
	 padding-left: 20px;
	 background-color: #d6e2dd;
	/*background-image: url('../images/Blue.png');
	*/
	 background-size: auto;
}
 .rf-original-box .container.header {
	 max-width: none;
}
 .rf-original-box .container.container--nav-size {
	 max-width: 1440px;
}
 .rf-original-box .container.max-1440 {
	 max-width: 1440px;
	 padding-right: 20px;
	 padding-left: 20px;
}
 .rf-original-box .btn--primary {
	 min-width: 208px;
	 padding: 17px 27px;
	 border-style: solid;
	 border-width: 2px;
	 border-color: #04243a;
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: #04243a;
	 font-family: 'Open Sans', sans-serif;
	 font-size: 18px;
	 line-height: 22px;
	 text-align: center;
}
 .rf-original-box .btn--primary:hover {
	 background-color: rgba(4, 36, 58, 0.9);
}
 .rf-original-box .btn--primary.mobile-version-hero {
	 margin-top: 104px;
	 margin-bottom: 205px;
	 font-size: 18px;
}
 .rf-original-box .btn--primary.how-it-works {
	 margin-top: 104px;
	 margin-bottom: 140px;
}
 .rf-original-box .btn--primary.mobile-version-hero {
	 display: none;
	 margin-top: 104px;
	 margin-bottom: 205px;
	 font-size: 18px;
}
 .rf-original-box .btn--primary.website-version-hero.button--no-margin {
	 margin-bottom: 0px;
}
 .rf-original-box .btn--primary.referal {
	 min-width: 200px;
}
 .rf-original-box .hero-content {
	 position: static;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 1193px;
	 margin-right: auto;
	 margin-left: auto;
	 padding-top: 205px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
}
 .rf-original-box .legacy-ingo-grid {
	 display: -ms-grid;
	 display: grid;
	 max-width: 860px;
	 padding-top: 57px;
	 padding-bottom: 111px;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 100px;
	 grid-row-gap: 59px;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
}
 .rf-original-box .exit-grid {
	 display: -ms-grid;
	 display: grid;
	 max-width: 940px;
	 padding-bottom: 76px;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 100px;
	 grid-row-gap: 60px;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
}
 .rf-original-box .founders-grid {
	 display: -ms-grid;
	 display: grid;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 50px;
	 grid-row-gap: 16px;
	 -ms-grid-columns: 1fr 1fr 1fr;
	 grid-template-columns: 1fr 1fr 1fr;
	 -ms-grid-rows: auto;
	 grid-template-rows: auto;
}
 .rf-original-box .footer-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 padding-top: 78px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
}
 .rf-original-box .link-container {
	 display: -ms-grid;
	 display: grid;
	 min-width: 300px;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 16px;
	 grid-row-gap: 24px;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
}
 .rf-original-box .exit-heading {
	 font-family: 'Open Sans', sans-serif;
	 color: #27806e;
	 font-size: 20px;
	 line-height: 24px;
	 font-weight: 800;
}
 .rf-original-box .speak-bubble-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
}
 .rf-original-box .team-member-container {
	 margin-bottom: 104px;
}
 .rf-original-box .image {
	 width: 14px;
	 height: 14px;
	 margin-top: 8px;
	 margin-left: 8px;
}
 .rf-original-box .team-member-image {
	 width: 100%;
	 height: 100%;
	 max-width: none;
	 border-radius: 100px 0px 100px 100px;
}
 .rf-original-box .team-member-text {
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 400;
	 text-align: center;
}
 .rf-original-box .team-member-link-bloc {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 text-decoration: none;
}
 .rf-original-box .hero-deal-container {
	 position: relative;
	 width: 260px;
	 height: 342px;
	 padding: 24px 24px 27px;
	 border-top-right-radius: 100px;
	 border-bottom-left-radius: 100px;
	 background-color: #04243a;
}
 .rf-original-box .deal-closed {
	 position: static;
	 bottom: 27px;
	 display: inline-block;
	 padding-left: 4px;
	 border-radius: 20px;
	 background-color: transparent;
	 font-family: 'Open Sans', sans-serif;
	 color: #fff;
	 font-size: 10px;
	 line-height: 13px;
}
 .rf-original-box .deal-info {
	 display: inline-block;
	 margin-top: 9px;
	 margin-right: 8px;
	 margin-bottom: 16px;
	 padding: 5px;
	 border-radius: 6px;
	 background-color: #486575;
	 font-family: 'Open Sans', sans-serif;
	 color: #fff;
	 font-size: 12px;
	 line-height: 15px;
}
 .rf-original-box .hero-heading {
	 display: inline-block;
	 font-family: 'PT Serif', serif;
	 font-size: 56px;
	 font-weight: 700;
}
 .rf-original-box .hero-info-container {
	 display: block;
	 max-width: 700px;
	 font-weight: 400;
}
 .rf-original-box .nav-link {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding: 17px 8px;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 color: #27806e;
	 line-height: 18px;
	 text-decoration: none;
}
 .rf-original-box .nav-link:hover {
	 color: #04243a;
}
 .rf-original-box .nav-link.hidden {
	 display: none;
}
 .rf-original-box .nav-link.hidden.w--current {
	 display: none;
}
 .rf-original-box .nav-link.contact-us {
	 background-color: #e1ff00;
}
 .rf-original-box .nav-button {
	 min-width: 130px;
	 margin-top: 14px;
	 margin-bottom: 14px;
	 margin-left: 16px;
	 padding: 11px 17.5px;
	 border-radius: 8px;
	 background-color: #04243a;
	 font-family: 'Open Sans', sans-serif;
	 color: #fff;
	 line-height: 18px;
	 text-align: center;
}
 .rf-original-box .nav-button:hover {
	 background-color: rgba(4, 36, 58, 0.9);
}
 .rf-original-box .nav-button.w--current {
	 color: #fff;
}
 .rf-original-box .nav-button.neutral {
	 margin: 0px;
	 border: 1px solid #ccc;
	 -webkit-transition: all 300ms ease;
	 transition: all 300ms ease;
	 font-weight: 500;
}
 .rf-original-box .nav-button.neutral:hover {
	 background-color: #edf3f0;
}
 .rf-original-box .legacy-heading {
	 max-width: 780px;
	 padding-top: 104px;
	 font-family: 'PT Serif', serif;
	 color: #d6e2dd;
	 font-size: 40px;
}
 .rf-original-box .legacy-info {
	 max-width: 380px;
	 font-family: 'Open Sans', sans-serif;
	 color: #d6e2dd;
	 font-size: 18px;
	 line-height: 28px;
	 font-weight: 400;
}
 .rf-original-box .legacy-image {
	 height: 70px;
	 margin-bottom: 21px;
}
 .rf-original-box .legacy-image.taller {
	 height: 80px;
	 margin-bottom: 11px;
}
 .rf-original-box .navbar {
	 z-index: 1001;
	 background-color: transparent;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .exit-speack-bubble {
	 padding: 45px 48px 60px 52px;
	 border-top-right-radius: 100px;
	 border-bottom-left-radius: 100px;
	 border-bottom-right-radius: 100px;
	 background-color: #27806e;
}
 .rf-original-box .speach-bubble-text {
	 display: inline;
	 margin-bottom: 16px;
	 font-family: 'PT Serif', serif;
	 color: #fff;
	 font-size: 48px;
	 line-height: 63px;
	 font-style: italic;
}
 .rf-original-box .exit-founder {
	 margin-top: 16px;
	 font-family: 'Open Sans', sans-serif;
	 color: #d6e2dd;
	 font-size: 16px;
	 line-height: 19px;
}
 .rf-original-box .per-ola-image {
	 border-radius: 100px;
}
 .rf-original-box .logo-heading {
	 padding-top: 148px;
	 padding-bottom: 51px;
	 font-family: 'PT Serif', serif;
	 color: #fff;
}
 .rf-original-box .founders-heading {
	 padding-top: 104px;
	 font-family: 'PT Serif', serif;
	 color: #04243a;
	 text-align: center;
}
 .rf-original-box .team-member-name {
	 padding-top: 24px;
	 padding-bottom: 16px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 24px;
	 line-height: 29px;
	 font-weight: 600;
	 text-decoration: none;
}
 .rf-original-box .heading-6 {
	 padding-top: 104px;
	 padding-bottom: 104px;
	 font-family: 'PT Serif', serif;
	 color: #04243a;
	 text-align: center;
}
 .rf-original-box .works-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 background-image: none;
	 background-size: auto;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .selling-heading {
	 padding-top: 128px;
	 font-family: 'PT Serif', serif;
	 color: #fff;
}
 .rf-original-box .footer-paragraph {
	 padding-top: 40px;
	 padding-bottom: 24px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 400;
}
 .rf-original-box .footer-copyright {
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
}
 .rf-original-box .footer-link {
	 margin-bottom: 0px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 400;
	 text-align: right;
}
 .rf-original-box .hero-deal-latest-deal {
	 font-family: 'Open Sans', sans-serif;
	 color: #fff;
	 font-size: 12px;
	 line-height: 15px;
	 font-weight: 700;
}
 .rf-original-box .hero-deal-price {
	 font-family: 'Open Sans', sans-serif;
	 color: #fff;
	 font-size: 32px;
	 line-height: 39px;
	 font-weight: 700;
}
 .rf-original-box .hero-deal-category {
	 margin-top: 4px;
	 margin-bottom: 16px;
	 font-family: 'Open Sans', sans-serif;
	 color: #bdc7cc;
}
 .rf-original-box .hero-deal-paragraph {
	 margin-bottom: 16px;
	 font-family: 'Open Sans', sans-serif;
	 color: #bdc7cc;
	 font-size: 12px;
	 line-height: 13px;
}
 .rf-original-box .hero-left {
	 margin-right: 70px;
	 color: #04243a;
}
 .rf-original-box .brand-fits-heading {
	 margin-bottom: 104px;
	 font-family: 'PT Serif', serif;
	 color: #04243a;
	 text-align: center;
}
 .rf-original-box .brand-fits-bottom-header {
	 position: static;
	 margin-top: 104px;
	 margin-bottom: 40px;
	 font-family: 'PT Serif', serif;
	 color: #04243a;
	 font-size: 48px;
	 line-height: 58px;
	 text-align: center;
}
 .rf-original-box .brand-fit-image {
	 width: 100%;
	 height: 100%;
	 max-width: none;
	 box-shadow: 0 16px 16px -8px rgba(94, 107, 102, 0.5);
}
 .rf-original-box .brand-fit-heading {
	 padding-top: 30px;
	 padding-bottom: 17px;
	 font-family: 'PT Serif', serif;
	 color: #58a999;
	 font-size: 40px;
	 line-height: 48px;
	 font-weight: 700;
	 text-align: center;
}
 .rf-original-box .brand-fit-paragraph {
	 max-width: 266px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 28px;
	 font-weight: 600;
	 text-align: center;
}
 .rf-original-box .brand-fit-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
}
 .rf-original-box .brand-fits-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 padding-top: 104px;
	 padding-bottom: 104px;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .team-image-holder {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-height: 164px;
	 max-width: 164px;
	 margin-right: auto;
	 margin-left: auto;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
}
 .rf-original-box .footer-bottom {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 padding-bottom: 80px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: end;
	 -webkit-align-items: flex-end;
	 -ms-flex-align: end;
	 align-items: flex-end;
}
 .rf-original-box .logo-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding-bottom: 148px;
	 -webkit-box-pack: end;
	 -webkit-justify-content: flex-end;
	 -ms-flex-pack: end;
	 justify-content: flex-end;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .how-it-works-info-container {
	 display: -ms-grid;
	 display: grid;
	 overflow: visible;
	 max-width: 595px;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 16px;
	 grid-row-gap: 16px;
	 -ms-grid-columns: 1fr 0.25fr 4.75fr;
	 grid-template-columns: 1fr 0.25fr 4.75fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
}
 .rf-original-box .how-it-works-heading {
	 font-family: 'Open Sans', sans-serif;
	 font-size: 20px;
	 line-height: 24px;
	 font-weight: 600;
}
 .rf-original-box .how-it-works-paragraph {
	 max-width: 439px;
	 font-family: 'Open Sans', sans-serif;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 400;
}
 .rf-original-box .how-it-works-day {
	 font-family: 'Open Sans', sans-serif;
	 font-size: 18px;
	 line-height: 22px;
	 font-weight: 600;
	 text-align: right;
}
 .rf-original-box .hero-deal-block-one {
	 width: 212px;
	 height: 6px;
	 margin-bottom: 8px;
	 background-color: #bdc7cc;
}
 .rf-original-box .subpage-heading {
	 max-width: 940px;
	 margin-right: auto;
	 margin-bottom: 104px;
	 margin-left: auto;
	 padding-top: 204px;
	 font-family: 'PT Serif', serif;
	 color: #04243a;
	 font-size: 58px;
	 text-align: center;
}
 .rf-original-box .subpage-heading.refer {
	 font-family: 'PT Serif', serif;
	 text-align: center;
}
 .rf-original-box .working-grid {
	 display: -ms-grid;
	 display: grid;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 126px;
	 grid-row-gap: 16px;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto;
	 grid-template-rows: auto;
}
 .rf-original-box .working-header {
	 font-family: 'PT Serif', serif;
	 color: #d6e2dd;
	 font-size: 24px;
	 line-height: 29px;
	 font-weight: 400;
	 text-align: center;
}
 .rf-original-box .working-container {
	 max-width: 354px;
}
 .rf-original-box .working-paragraph {
	 padding-top: 16px;
	 padding-bottom: 117px;
	 color: #a7bcbc;
	 font-size: 18px;
	 line-height: 28px;
	 font-weight: 400;
	 text-align: center;
}
 .rf-original-box .working-title {
	 padding-top: 200px;
	 padding-bottom: 44px;
	 font-family: 'PT Serif', serif;
	 color: #d6e2dd;
	 text-align: center;
}
 .rf-original-box .offer-grid {
	 display: -ms-grid;
	 display: grid;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 20px;
	 grid-row-gap: 40px;
	 grid-template-areas: "Area-3 Area-3 Area-4 Area-4 Area-5 Area-5" ". Area Area Area-2 Area-2 .";
	 -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
	 grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	 -ms-grid-rows: auto 40px auto;
	 grid-template-rows: auto auto;
}
 .rf-original-box .offer-img-container {
	 max-height: 102px;
	 max-width: 102px;
	 margin-right: auto;
	 margin-left: auto;
	 border-radius: 5px;
}
 .rf-original-box .offer-text {
	 max-width: 162px;
	 padding-top: 23px;
	 padding-bottom: 48px;
	 color: #d6e2dd;
	 font-size: 18px;
	 line-height: 24px;
	 font-weight: 400;
	 text-align: center;
}
 .rf-original-box .offer-container {
	 max-width: 300px;
	 padding-right: 53px;
	 padding-left: 53px;
}
 .rf-original-box .offer-title {
	 margin-bottom: 64px;
	 font-family: 'PT Serif', serif;
	 color: #d6e2dd;
	 text-align: center;
}
 .rf-original-box .offer-contarer {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .navbar-container {
	 position: fixed;
	 left: 0%;
	 top: 0%;
	 right: 0%;
	 bottom: auto;
	 z-index: 1001;
	 margin-right: auto;
	 margin-left: auto;
	 background-color: rgba(255, 255, 255, 0.9);
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .cookie-policy-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 940px;
	 margin-right: auto;
	 margin-left: auto;
	 padding-top: 0px;
	 padding-bottom: 137px;
}
 .rf-original-box .team-hero-right {
	 color: #04243a;
	 font-size: 18px;
	 line-height: 28px;
}
 .rf-original-box .team-hero-left {
	 display: block;
	 padding-right: 0px;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 28px;
	 text-align: center;
}
 .rf-original-box .div-block-23 {
	 display: -ms-grid;
	 display: grid;
	 padding-bottom: 16px;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 16px;
	 grid-row-gap: 16px;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
}
 .rf-original-box .drives-line {
	 width: 2px;
	 height: 73px;
	 margin-right: 26px;
	 background-color: #fff;
}
 .rf-original-box .drives-block {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 380px;
	 margin-bottom: 88px;
}
 .rf-original-box .drives-heading {
	 margin-bottom: 16px;
	 font-family: 'Cormorant Garamond', sans-serif;
	 color: #fff;
	 font-size: 24px;
	 line-height: 29px;
	 font-weight: 600;
}
 .rf-original-box .drives-paragraph {
	 color: #fff;
	 font-size: 18px;
	 line-height: 28px;
}
 .rf-original-box .heading-17 {
	 padding-top: 104px;
	 padding-bottom: 80px;
	 color: #fff;
}
 .rf-original-box .body {
	 background-color: #d6e2dd;
	 font-family: Montserrat, sans-serif;
	 font-weight: 400;
}
 .rf-original-box .body.body--blogs {
	 background-color: #fff;
}
 .rf-original-box .hero-deal-block-short {
	 width: 180px;
	 height: 6px;
	 background-color: #bdc7cc;
}
 .rf-original-box .deal-closed-container {
	 position: absolute;
	 left: auto;
	 top: auto;
	 right: 24px;
	 bottom: 27px;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding: 5px 8px;
	 -webkit-box-orient: horizontal;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: row;
	 -ms-flex-direction: row;
	 flex-direction: row;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 border-radius: 100px;
	 background-color: #27806e;
}
 .rf-original-box .hero-paragraph {
	 max-width: 600px;
	 padding-top: 37px;
	 padding-bottom: 47px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 28px;
	 font-weight: 400;
}
 .rf-original-box .exit-container {
	 width: 100%;
	 padding-top: 104px;
	 padding-bottom: 104px;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .heading-19 {
	 margin-bottom: 104px;
	 font-family: 'PT Serif', serif;
	 text-align: center;
}
 .rf-original-box .hero-heading-bold {
	 color: #27806e;
	 font-style: italic;
	 white-space: nowrap;
}
 .rf-original-box .header-section {
	 padding-right: 250px;
	 padding-left: 250px;
}
 .rf-original-box .header-section.image-bg {
	 padding-right: 0px;
	 padding-left: 0px;
	 background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	 background-size: auto;
}
 .rf-original-box .exit-text {
	 padding-top: 16px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 28px;
}
 .rf-original-box .exit-info-container {
	 max-width: 380px;
}
 .rf-original-box .round-image-holder {
	 margin-right: 32px;
}
 .rf-original-box .dot {
	 width: 8px;
	 height: 8px;
	 border-radius: 100px;
	 background-color: #04243a;
}
 .rf-original-box .line {
	 width: 1px;
	 height: 134px;
	 background-color: #04243a;
}
 .rf-original-box .line.dotted {
	 height: 10px;
	 margin-bottom: 2px;
}
 .rf-original-box .line.dotted.d2 {
	 height: 5px;
}
 .rf-original-box .line.dotted.d3 {
	 height: 4px;
}
 .rf-original-box .line.dotted.d4 {
	 height: 2px;
}
 .rf-original-box .primary-button-white {
	 margin-top: 104px;
	 margin-bottom: 128px;
	 padding: 17px 27px;
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: #fff;
	 font-family: Montserrat, sans-serif;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 22px;
}
 .rf-original-box .primary-button-white.padding {
	 min-width: 180px;
	 margin-top: 40px;
	 margin-bottom: 104px;
	 font-family: 'Open Sans', sans-serif;
	 text-align: center;
}
 .rf-original-box .primary-button-white.padding:hover {
	 background-color: #e4e9ec;
}
 .rf-original-box .primary-button-white.brand-selling {
	 min-width: 208px;
	 border-style: solid;
	 border-width: 1px;
	 border-color: #fff;
	 font-family: 'Open Sans', sans-serif;
	 text-align: center;
}
 .rf-original-box .primary-button-white.brand-selling:hover {
	 background-color: rgba(255, 255, 255, 0.9);
}
 .rf-original-box .primary-button-white.brand-selling.secondary {
	 border-style: solid;
	 border-width: 1px;
	 border-color: #fff;
	 background-color: transparent;
	 color: #fff;
}
 .rf-original-box .primary-button-white.brand-selling.secondary:hover {
	 background-color: rgba(255, 255, 255, 0.1);
}
 .rf-original-box .footer-button {
	 min-width: 160px;
	 padding: 11px 18px;
	 border-style: solid;
	 border-width: 1px;
	 border-color: #04243a;
	 border-radius: 8px;
	 background-color: #04243a;
	 font-family: 'Open Sans', sans-serif;
	 font-size: 14px;
	 line-height: 18px;
	 text-align: center;
}
 .rf-original-box .footer-button:hover {
	 background-color: rgba(4, 36, 58, 0.9);
}
 .rf-original-box .footer-button.footer-btn--secondary {
	 border: 1px solid #000;
	 background-color: #d6e2dd;
	 color: #04243a;
	 font-weight: 600;
}
 .rf-original-box .footer-button.footer-btn--secondary:hover {
	 background-color: rgba(167, 188, 188, 0.45);
}
 .rf-original-box .footer-logo {
	 max-height: 23.5px;
}
 .rf-original-box .footer-left {
	 max-width: 362px;
}
 .rf-original-box .founders-paragraph {
	 max-width: 780px;
	 margin-right: auto;
	 margin-left: auto;
	 padding-top: 24px;
	 padding-bottom: 64px;
	 font-family: 'Open Sans', sans-serif;
	 font-size: 18px;
	 line-height: 22px;
	 font-weight: 400;
	 text-align: center;
}
 .rf-original-box .image-4 {
	 height: 25px;
	 max-width: none;
}
 .rf-original-box .brand {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-top: 20px;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
}
 .rf-original-box .website-version-this-could-be-you-container {
	 position: relative;
	 left: auto;
	 top: 0%;
	 right: 9%;
	 bottom: 0%;
	 max-width: 248px;
}
 .rf-original-box .hero-right {
	 position: relative;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
}
 .rf-original-box .couldbeyou {
	 margin-top: 15px;
	 padding-right: 25px;
	 padding-bottom: 51px;
}
 .rf-original-box .mouse-icon-holder {
	 position: absolute;
	 left: 0%;
	 top: auto;
	 right: 0%;
	 bottom: 0%;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
}
 .rf-original-box .brand-fit-text-container {
	 max-width: 620px;
}
 .rf-original-box .brand-fit-image-container {
	 max-height: 300px;
	 max-width: 284px;
}
 .rf-original-box .top-image-container {
	 position: static;
	 left: 0%;
	 top: auto;
	 right: 0%;
	 bottom: 0%;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-height: 356px;
	 max-width: 780px;
	 margin-right: auto;
	 margin-bottom: -170px;
	 margin-left: auto;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
}
 .rf-original-box .blog-title {
	 padding-top: 104px;
	 padding-bottom: 64px;
	 color: #04243a;
	 text-align: center;
}
 .rf-original-box .blog-heading {
	 margin-top: 16px;
	 margin-bottom: 8px;
	 font-size: 24px;
	 line-height: 29px;
	 font-weight: 400;
}
 .rf-original-box .blog-read-more-text {
	 color: #27806e;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 600;
}
 .rf-original-box .blog-read-more-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
}
 .rf-original-box .blog-image-container {
	 width: 100%;
	 max-height: 166px;
}
 .rf-original-box .blog-container {
	 max-width: 300px;
}
 .rf-original-box .blog-image {
	 width: 100%;
	 height: 100%;
	 max-height: 166px;
}
 .rf-original-box .primary-button-outline {
	 display: block;
	 margin-top: 104px;
	 margin-bottom: 128px;
	 padding: 17px 27px;
	 border-style: solid;
	 border-width: 2px;
	 border-color: #04243a;
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: transparent;
	 font-family: Montserrat, sans-serif;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 22px;
}
 .rf-original-box .blog-grid {
	 display: -ms-grid;
	 display: grid;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 20px;
	 grid-row-gap: 20px;
	 -ms-grid-columns: 1fr 1fr 1fr;
	 grid-template-columns: 1fr 1fr 1fr;
	 -ms-grid-rows: auto;
	 grid-template-rows: auto;
}
 .rf-original-box .selling-container {
	 width: 100%;
}
 .rf-original-box .founders-container {
	 width: 100%;
}
 .rf-original-box .partner-container {
	 width: 100%;
}
 .rf-original-box .legacy-container {
	 width: 100%;
}
 .rf-original-box .blog-containerr {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .careers-hero-image {
	 -webkit-box-flex: 1;
	 -webkit-flex: 1;
	 -ms-flex: 1;
	 flex: 1;
}
 .rf-original-box .working {
	 max-width: 780px;
	 margin-right: auto;
	 margin-left: auto;
}
 .rf-original-box .mobile-version-this-could-be-you-container {
	 position: relative;
	 left: auto;
	 top: 0%;
	 right: 9%;
	 bottom: 0%;
	 display: none;
	 max-width: 248px;
}
 .rf-original-box ._32space {
	 display: none;
}
 .rf-original-box .mouse-icon {
	 padding-bottom: 32px;
}
 .rf-original-box .brand-fit-grid {
	 display: -ms-grid;
	 display: grid;
	 grid-auto-columns: 1fr;
	 grid-column-gap: 16px;
	 grid-row-gap: 16px;
	 -ms-grid-columns: 1fr 1fr 1fr;
	 grid-template-columns: 1fr 1fr 1fr;
	 -ms-grid-rows: auto;
	 grid-template-rows: auto;
}
 .rf-original-box .check-mark-text-holder {
	 position: relative;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 align-self: center;
}
 .rf-original-box .brand-fits-check {
	 position: absolute;
	 left: 0%;
	 top: auto;
	 right: 0%;
	 bottom: 0%;
	 width: 148px;
	 height: 103px;
	 margin-right: auto;
	 margin-bottom: 13px;
	 margin-left: auto;
}
 .rf-original-box .nav-menu {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
}
 .rf-original-box .hero-deal-check {
	 margin-bottom: 2px;
}
 .rf-original-box .hero-heading-boldd {
	 color: #27806e;
	 font-style: italic;
}
 .rf-original-box .valuation-modal-wrapper {
	 position: fixed;
	 left: 0%;
	 top: 0%;
	 right: 0%;
	 bottom: 0%;
	 z-index: 1002;
	 display: none;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 grid-auto-columns: 1fr;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
	 background-color: rgba(4, 36, 58, 0.8);
}
 .rf-original-box .modal {
	 position: relative;
	 z-index: 2;
	 display: block;
	 max-height: 700px;
	 max-width: 700px;
	 padding: 56px 100px;
	 border-top-right-radius: 100px;
	 border-bottom-left-radius: 100px;
	 background-color: #d6e2dd;
}
 .rf-original-box .text-field {
	 height: 56px;
	 margin-top: 24px;
	 margin-bottom: 24px;
	 padding-top: 19px;
	 padding-bottom: 18px;
	 padding-left: 16px;
	 border: 0px solid #000;
	 border-radius: 8px;
	 font-size: 16px;
	 line-height: 19px;
}
 .rf-original-box .text-field.message {
	 min-height: 100px;
}
 .rf-original-box .paragraph-9 {
	 margin-top: 18px;
	 margin-bottom: 36px;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
}
 .rf-original-box .checkbox-label {
	 margin-bottom: 0px;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
}
 .rf-original-box .heading-21 {
	 color: #04243a;
}
 .rf-original-box .close-icon {
	 position: static;
	 left: auto;
	 top: 0%;
	 right: 0%;
	 bottom: auto;
}
 .rf-original-box .close-icon-holder {
	 position: absolute;
	 left: auto;
	 top: 0%;
	 right: 0%;
	 bottom: auto;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 56px;
	 height: 56px;
	 margin-top: 0px;
	 padding-top: 0px;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 border-radius: 100px;
	 background-color: #d66262;
}
 .rf-original-box .sumbit-button-holder {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: end;
	 -webkit-justify-content: flex-end;
	 -ms-flex-pack: end;
	 justify-content: flex-end;
}
 .rf-original-box .checkbox {
	 width: 24px;
	 height: 24px;
	 min-height: 24px;
	 min-width: 24px;
	 margin-right: 9px;
	 border: 0px solid transparent;
	 border-radius: 0px 100px 100px;
	 background-color: #fff;
}
 .rf-original-box .checkbox.w--redirected-checked {
	 background-color: #27806e;
}
 .rf-original-box .checkbox-field {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .close-modal {
	 position: fixed;
	 left: 0%;
	 top: 0%;
	 right: 0%;
	 bottom: 0%;
	 z-index: 1;
	 background-color: transparent;
}
 .rf-original-box .policy-rich-text {
	 max-width: 700px;
	 font-weight: 400;
}
 .rf-original-box .policy-rich-text h2 {
	 font-family: 'PT Serif', serif;
	 font-size: 36px;
	 line-height: 45px;
}
 .rf-original-box .policy-rich-text h4 {
	 font-family: 'Cormorant Garamond', sans-serif;
	 font-size: 24px;
	 font-weight: 700;
}
 .rf-original-box .policy-rich-text h3 {
	 font-family: 'Cormorant Garamond', sans-serif;
	 font-weight: 700;
}
 .rf-original-box .body-4 {
	 font-family: Montserrat, sans-serif;
	 font-weight: 500;
}
 .rf-original-box .body-5 {
	 font-family: Montserrat, sans-serif;
}
 .rf-original-box .moneybage-holder {
	 position: relative;
	 bottom: 10px;
	 display: none;
	 width: 33px;
	 height: 44px;
	 margin-left: 6px;
}
 .rf-original-box .error-message {
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: #d66262;
	 color: #04243a;
}
 .rf-original-box .text-block-6 {
	 border-top-right-radius: 16px;
	 border-bottom-right-radius: 16px;
	 color: #fff;
}
 .rf-original-box .success-message {
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: #27806e;
	 color: #fff;
}
 .rf-original-box .cookie-modal {
	 position: fixed;
	 left: 0%;
	 top: auto;
	 right: auto;
	 bottom: 0%;
	 z-index: 1002;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-right: 24px;
	 margin-bottom: 24px;
	 margin-left: 24px;
	 padding: 12px;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 border-style: solid;
	 border-width: 1px;
	 border-color: #04243a;
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: #d6e2dd;
	 font-family: 'Open Sans', sans-serif;
}
 .rf-original-box .cookie-button {
	 min-width: 75px;
	 padding: 11px 18px;
	 border-radius: 0px 8px 8px;
	 background-color: #27806e;
	 font-family: 'Open Sans', sans-serif;
	 font-size: 14px;
	 line-height: 18px;
}
 .rf-original-box .cookie-button:hover {
	 background-color: rgba(39, 128, 110, 0.9);
}
 .rf-original-box .cookie-text {
	 padding-right: 24px;
	 padding-left: 24px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
}
 .rf-original-box .policy-link {
	 color: #04243a;
	 text-decoration: underline;
}
 .rf-original-box .insignia-logo {
	 max-height: 67px;
	 margin-right: 96px;
}
 .rf-original-box .nordstar-logo {
	 max-height: 36px;
}
 .rf-original-box .logo-text-container {
	 text-align: center;
}
 .rf-original-box .form-block {
	 display: block;
}
 .rf-original-box .referal-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .bold {
	 color: #27806e;
}
 .rf-original-box .referal-box {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-bottom: 164px;
	 padding: 32px 40px 32px 45px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 border-radius: 24px;
	 background-color: #f7f9f8;
}
 .rf-original-box .moneybag-wrapper {
	 min-width: 26px;
}
 .rf-original-box .referal-paragraph {
	 margin-right: 40px;
	 margin-left: 35px;
	 font-family: 'Open Sans', sans-serif;
	 font-size: 20px;
	 line-height: 24px;
}
 .rf-original-box .referal-modal-wrapper {
	 position: fixed;
	 left: 0%;
	 top: 0%;
	 right: 0%;
	 bottom: 0%;
	 z-index: 1002;
	 display: none;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 grid-auto-columns: 1fr;
	 -ms-grid-columns: 1fr 1fr;
	 grid-template-columns: 1fr 1fr;
	 -ms-grid-rows: auto auto;
	 grid-template-rows: auto auto;
	 background-color: rgba(4, 36, 58, 0.8);
}
 .rf-original-box .referal-top-conatiner {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .language-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-bottom: 35px;
	 -webkit-box-orient: horizontal;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: row;
	 -ms-flex-direction: row;
	 flex-direction: row;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
}
 .rf-original-box .language-container.nav {
	 position: relative;
	 width: 106px;
	 height: 64px;
	 margin-bottom: 0px;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
}
 .rf-original-box .globe-wrapper {
	 min-width: 16px;
}
 .rf-original-box .arrow {
	 min-width: 8px;
}
 .rf-original-box .language-text {
	 min-width: 35px;
	 margin-right: 3px;
	 margin-left: 8px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
	 cursor: default;
}
 .rf-original-box .select-language-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding-bottom: 11px;
	 -webkit-box-orient: horizontal;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: row;
	 -ms-flex-direction: row;
	 flex-direction: row;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 color: #27806e;
}
 .rf-original-box .select-language-container:hover {
	 color: #04243a;
}
 .rf-original-box .select-language-drop {
	 display: none;
	 width: 6px;
	 height: 6px;
	 margin-right: 8px;
	 border-style: solid;
	 border-width: 1px;
	 border-color: #27806e;
	 border-radius: 0px 10px 10px;
}
 .rf-original-box .select-language-drop.filled {
	 display: none;
	 background-color: #27806e;
}
 .rf-original-box .select-language-text {
	 padding-left: 8px;
	 line-height: 18px;
	 font-weight: 500;
}
 .rf-original-box .select-language-text:hover {
	 color: #04243a;
}
 .rf-original-box .navbar-con {
	 max-width: 1440px;
	 margin-right: auto;
	 margin-left: auto;
	 padding-right: 32px;
	 padding-left: 32px;
}
 .rf-original-box .nav-droplet {
	 width: 6px;
	 height: 6px;
	 min-height: 6px;
	 min-width: 6px;
	 margin-top: 4px;
	 border-style: solid;
	 border-width: 1px;
	 border-color: #27806e;
	 border-top-right-radius: 10px;
	 border-bottom-left-radius: 10px;
	 border-bottom-right-radius: 10px;
}
 .rf-original-box .nav-link-text {
	 font-family: 'Open Sans', sans-serif;
	 font-weight: 400;
}
 .rf-original-box .flag-wrapper {
	 width: 30px;
	 padding-bottom: 2px;
}
 .rf-original-box .lauguage-dropdown-balloon {
	 display: none;
	 width: 180px;
	 margin-top: -30px;
	 padding: 15px 16px 3px;
	 border-radius: 8px;
	 background-color: #f7f9f8;
}
 .rf-original-box .lauguage-dropdown-balloon.nav {
	 position: relative;
	 left: -72px;
	 top: 36px;
	 bottom: 0%;
	 z-index: 1001;
	 display: none;
	 min-width: 180px;
}
 .rf-original-box .chose-language {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 90px;
	 margin: 14px 16px;
	 padding: 10px 16px;
	 -webkit-box-align: end;
	 -webkit-align-items: flex-end;
	 -ms-flex-align: end;
	 align-items: flex-end;
	 border-radius: 8px;
}
 .rf-original-box .chose-language:hover {
	 background-color: rgba(4, 36, 58, 0.1);
}
 .rf-original-box .select-language-container-nav {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding-bottom: 11px;
	 -webkit-box-orient: horizontal;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: row;
	 -ms-flex-direction: row;
	 flex-direction: row;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 color: #27806e;
}
 .rf-original-box .select-language-container-nav:hover {
	 color: #04243a;
}
 .rf-original-box .hidden {
	 display: none;
}
 .rf-original-box .language-footer {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 90px;
	 margin: 14px 16px;
	 padding: 10px 16px;
	 -webkit-box-align: end;
	 -webkit-align-items: flex-end;
	 -ms-flex-align: end;
	 align-items: flex-end;
	 border-radius: 8px;
}
 .rf-original-box .language-footer:hover {
	 background-color: rgba(4, 36, 58, 0.1);
}
 .rf-original-box .language-footer.footer {
	 margin-left: 0px;
}
 .rf-original-box .lauguage-dropdown-balloon-footer {
	 display: none;
	 width: 180px;
	 margin-top: -30px;
	 padding: 15px 16px 3px;
	 border-radius: 8px;
	 background-color: #f7f9f8;
}
 .rf-original-box .as-seen-on-container {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
}
 .rf-original-box .as-seen-on-heading {
	 padding-top: 104px;
	 padding-bottom: 56px;
	 font-family: 'PT Serif', serif;
	 color: #04243a;
	 text-align: center;
}
 .rf-original-box .as-seen-on-card {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 100%;
	 height: 100%;
	 padding: 24px;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: end;
	 -webkit-align-items: flex-end;
	 -ms-flex-align: end;
	 align-items: flex-end;
	 border-radius: 8px;
	 background-color: #f7f9f8;
	 box-shadow: 0 10px 16px -12px rgba(0, 0, 0, 0.1);
	 text-decoration: none;
}
 .rf-original-box .slide-nav {
	 display: none;
}
 .rf-original-box .right-arrow {
	 display: none;
	 background-color: transparent;
}
 .rf-original-box .slider {
	 margin-right: 16px;
	 margin-bottom: 104px;
	 margin-left: 16px;
	 background-color: transparent;
}
 .rf-original-box .left-arrow {
	 display: none;
	 background-color: transparent;
}
 .rf-original-box .as-seen-as-card-logos {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
}
 .rf-original-box .as-seen-as-logo-wrapper {
	 overflow: hidden;
	 width: 64px;
	 height: 64px;
	 border-radius: 8px;
	 text-decoration: none;
}
 .rf-original-box .as-seen-as-logo-name {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-left: 24px;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 font-family: 'Open Sans', sans-serif;
	 color: #373f45;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 600;
}
 .rf-original-box .as-seen-as-logo {
	 min-height: 64px;
	 min-width: 64px;
	 -o-object-fit: cover;
	 object-fit: cover;
}
 .rf-original-box .as-seen-as-paragraph {
	 padding-top: 16px;
	 padding-bottom: 16px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 400;
}
 .rf-original-box .asa-read-more-wrapper {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding: 10px 16px;
	 -webkit-box-pack: end;
	 -webkit-justify-content: flex-end;
	 -ms-flex-pack: end;
	 justify-content: flex-end;
	 -webkit-box-align: end;
	 -webkit-align-items: flex-end;
	 -ms-flex-align: end;
	 align-items: flex-end;
	 border-radius: 8px;
}
 .rf-original-box .asa-read-more-text {
	 display: inline-block;
	 padding-right: 8px;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 19px;
	 font-weight: 300;
}
 .rf-original-box .as-seen-as-top {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
}
 .rf-original-box .slide {
	 width: 23%;
	 margin-right: 8px;
	 margin-left: 8px;
}
 .rf-original-box .slide.first {
	 margin-left: 0px;
}
 .rf-original-box .slide.last {
	 margin-right: 0px;
}
 .rf-original-box .as-seen-on-slider-container {
	 background-color: #d6e2dd;
}
 .rf-original-box .c-r--blogs-page {
	 margin-top: 90px;
	 margin-bottom: 120px;
}
 .rf-original-box .c-i--blogs-page {
	 margin-bottom: 60px;
}
 .rf-original-box .blog-flex {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
}
 .rf-original-box .blog-thumbnail {
	 width: 400px;
	 margin-left: 30px;
	 border-radius: 10px 60px;
	 -o-object-fit: cover;
	 object-fit: cover;
}
 .rf-original-box .blog-content {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-flex: 1;
	 -webkit-flex: 1;
	 -ms-flex: 1;
	 flex: 1;
}
 .rf-original-box .blog-sub-title {
	 margin-bottom: 20px;
	 font-family: Montserrat, sans-serif;
	 color: rgba(179, 179, 179, 0.7);
	 font-size: 20px;
	 line-height: 100%;
	 font-weight: 500;
	 letter-spacing: 0.1em;
	 text-transform: capitalize;
}
 .rf-original-box .blog-sub-title.smaller {
	 font-family: 'Open Sans', sans-serif;
	 font-size: 18px;
}
 .rf-original-box .blog-sub-title.lighter {
	 font-family: 'Open Sans', sans-serif;
	 color: #d6e2dd;
}
 .rf-original-box .blog-header {
	 margin-bottom: 15px;
	 font-family: Montserrat, sans-serif;
	 color: #27806e;
	 font-size: 35px;
	 line-height: 100%;
	 font-weight: 500;
}
 .rf-original-box .blog-header.blog--heading-smaller {
	 font-family: 'Open Sans', sans-serif;
	 font-size: 26px;
}
 .rf-original-box .blog-paragraph {
	 color: #04243a;
	 font-size: 18px;
	 line-height: 130%;
	 font-weight: 400;
}
 .rf-original-box .blog-paragraph.blog-paragraph--white {
	 max-width: 700px;
	 color: #fff;
}
 .rf-original-box .blog__content-wrapper {
	 -webkit-box-flex: 1;
	 -webkit-flex: 1;
	 -ms-flex: 1;
	 flex: 1;
}
 .rf-original-box .blog-date-wrapper {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 700px;
	 margin-top: 30px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .blog-date {
	 font-family: 'Open Sans', sans-serif;
	 color: grey;
	 font-size: 16px;
	 line-height: 100%;
	 font-weight: 400;
}
 .rf-original-box .blog-date.lighter {
	 font-family: 'Open Sans', sans-serif;
	 color: #e6e6e6;
}
 .rf-original-box .blog-read-link {
	 position: relative;
	 left: -2%;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding: 5px 15px 7px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 border-radius: 8px;
	 color: #04243a;
	 text-decoration: none;
}
 .rf-original-box .blog-read {
	 position: relative;
	 bottom: -2px;
	 z-index: 2;
	 font-size: 18px;
	 line-height: 100%;
	 font-weight: 600;
}
 .rf-original-box .blog-read-arrow {
	 position: relative;
	 z-index: 2;
}
 .rf-original-box .blog-bottom-line {
	 width: 50%;
	 height: 1px;
	 margin-top: 60px;
	 background-color: #ccc;
}
 .rf-original-box .cta--wrapper {
	 margin-bottom: 120px;
	 padding: 90px 60px;
	 border-radius: 10px;
	 background-color: #d6e2dd;
}
 .rf-original-box .cta--header2 {
	 margin-bottom: 20px;
	 color: #04243a;
	 font-size: 45px;
	 line-height: 130%;
	 font-weight: 700;
}
 .rf-original-box .cta--header3 {
	 color: #04243a;
	 font-size: 25px;
	 line-height: 120%;
	 font-weight: 600;
}
 .rf-original-box .margin-top--40px {
	 margin-top: 40px;
	 text-align: center;
}
 .rf-original-box .blog-post__wrapper {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding-top: 40px;
	 padding-bottom: 40px;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .author-hero-wrapper {
	 display: none;
	 width: 400px;
	 margin-left: 30px;
	 padding: 60px 30px;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 -webkit-box-flex: 0;
	 -webkit-flex: 0 0 auto;
	 -ms-flex: 0 0 auto;
	 flex: 0 0 auto;
	 border-radius: 80px 10px;
	 background-color: #fff;
}
 .rf-original-box .blog-post-author {
	 font-family: 'Open Sans', sans-serif;
	 color: #e6e6e6;
	 font-size: 16px;
	 font-weight: 600;
	 text-decoration: none;
}
 .rf-original-box .blog-post-author.author-name {
	 margin-left: 10px;
	 -webkit-transition: all 200ms ease;
	 transition: all 200ms ease;
	 font-family: 'Open Sans', sans-serif;
	 color: #65e580;
	 font-size: 18px;
	 font-weight: 400;
}
 .rf-original-box .blog-post-author.author-name:hover {
	 color: #04243a;
}
 .rf-original-box .author-name {
	 color: #27806e;
	 font-size: 20px;
	 line-height: 100%;
	 font-weight: 500;
	 text-transform: capitalize;
}
 .rf-original-box .author-name.margin-bottom {
	 margin-bottom: 10px;
	 font-weight: 400;
}
 .rf-original-box .author-image {
	 width: 164px;
	 height: 164px;
	 margin-top: 20px;
	 margin-bottom: 20px;
	 border-top-left-radius: 100px;
	 border-bottom-left-radius: 100px;
	 border-bottom-right-radius: 100px;
	 background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	 background-position: 50% 50%;
	 background-size: cover;
	 background-repeat: no-repeat;
}
 .rf-original-box .author-bio {
	 font-size: 18px;
	 line-height: 140%;
	 font-weight: 400;
}
 .rf-original-box .author-links-wrapper {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-top: 30px;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .author-links-block {
	 width: 50px;
	 height: 50px;
	 margin-right: 20px;
	 border-style: solid;
	 border-width: 1px;
	 border-color: transparent;
	 border-radius: 50%;
	 background-color: #fff;
	 box-shadow: 0 0 10px 0 rgba(4, 36, 58, 0.15);
	 -webkit-transition: all 300ms ease;
	 transition: all 300ms ease;
	 text-decoration: none;
}
 .rf-original-box .author-links-block:hover {
	 border-style: solid;
	 border-color: #dae7e7;
	 background-color: #f7f9f8;
}
 .rf-original-box .author-links-block.facebook {
	/*background-image: url('../images/facebook.svg');
	*/
	 background-position: 50% 50%;
	 background-size: 20px 20px;
	 background-repeat: no-repeat;
}
 .rf-original-box .author-links-block.youtube {
	/*background-image: url('../images/youtube.svg');
	*/
	 background-position: 50% 50%;
	 background-size: 20px 20px;
	 background-repeat: no-repeat;
}
 .rf-original-box .author-links-block.instagram {
	/*background-image: url('../images/instagram.svg');
	*/
	 background-position: 50% 50%;
	 background-size: 20px 20px;
	 background-repeat: no-repeat;
}
 .rf-original-box .author-links-block.email {
	/*background-image: url('../images/email.svg');
	*/
	 background-position: 50% 50%;
	 background-size: 22px 22px;
	 background-repeat: no-repeat;
}
 .rf-original-box .author-links-block.linkedin {
	/*background-image: url('../images/linkedIn.svg');
	*/
	 background-position: 50% 35%;
	 background-size: 35px 35px;
	 background-repeat: no-repeat;
}
 .rf-original-box .blog-main-image {
	 width: 100%;
	 margin-top: 20px;
	 margin-bottom: 40px;
	 text-align: center;
}
 .rf-original-box .image-soft-corner {
	 width: 100%;
	 border-radius: 30px;
}
 .rf-original-box .author-bottom-wrapper {
	 position: relative;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 padding-top: 60px;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: stretch;
	 -webkit-align-items: stretch;
	 -ms-flex-align: stretch;
	 align-items: stretch;
}
 .rf-original-box .author-bottom-wrapper.phone {
	 display: none;
}
 .rf-original-box .image-14 {
	 width: 200px;
	 height: 200px;
	 border-radius: 186px 0px 186px 186px;
	 -o-object-fit: cover;
	 object-fit: cover;
}
 .rf-original-box .author-container__content {
	 max-width: 600px;
	 margin-left: 30px;
}
 .rf-original-box .blog-written-by-text {
	 margin-bottom: 20px;
	 color: #b3b3b3;
	 font-size: 24px;
	 font-weight: 300;
}
 .rf-original-box .blog-post-section {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 margin-top: 20px;
	 margin-bottom: 30px;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .blog-section__collection-item {
	 width: 33.33%;
	 max-width: 450px;
	 margin-right: 30px;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: stretch;
	 -webkit-align-items: stretch;
	 -ms-flex-align: stretch;
	 align-items: stretch;
	 border-radius: 8px;
	 background-color: #fff;
	 box-shadow: 0 0 10px 0 rgba(4, 36, 58, 0.15);
}
 .rf-original-box .blog-section__collection-list {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-align: stretch;
	 -webkit-align-items: stretch;
	 -ms-flex-align: stretch;
	 align-items: stretch;
}
 .rf-original-box .flex-hz {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-orient: horizontal;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: row;
	 -ms-flex-direction: row;
	 flex-direction: row;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
}
 .rf-original-box .flex-hz.hz--space-between {
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
}
 .rf-original-box .heading2 {
	 color: #204c69;
}
 .rf-original-box .blog-post-header {
	 max-width: 800px;
	 margin-bottom: 30px;
	 font-family: 'Open Sans', sans-serif;
	 color: #fff;
	 font-size: 35px;
	 line-height: 120%;
	 font-weight: 600;
}
 .rf-original-box .author-top-line {
	 position: absolute;
	 left: 0%;
	 top: 0%;
	 right: 0%;
	 bottom: auto;
	 width: 50%;
	 height: 1px;
	 background-color: #b2b2b2;
}
 .rf-original-box .heading3 {
	 font-family: Montserrat, sans-serif;
	 color: #04243a;
	 font-size: 35px;
	 font-weight: 600;
}
 .rf-original-box .heading3.heading3--center {
	 font-family: 'Open Sans', sans-serif;
	 font-size: 30px;
	 text-align: center;
}
 .rf-original-box .div-center {
	 text-align: center;
}
 .rf-original-box .nav-button.neutral {
	 background-color: #f7f9f8;
	 color: #04243a;
	 font-size: 14px;
	 line-height: 110%;
}
 .rf-original-box .nav-button.neutral.margin-right {
	 margin-right: 30px;
}
 .rf-original-box .rich-text h1 {
	 margin-bottom: 20px;
	 font-family: Montserrat, sans-serif;
	 color: #04243a;
	 font-size: 40px;
	 line-height: 120%;
	 font-weight: 600;
}
 .rf-original-box .rich-text h2 {
	 margin-bottom: 20px;
	 font-family: Montserrat, sans-serif;
	 color: #04243a;
	 font-size: 30px;
	 line-height: 120%;
	 font-weight: 600;
}
 .rf-original-box .rich-text h3 {
	 margin-bottom: 20px;
	 color: #04243a;
	 font-size: 25px;
	 line-height: 120%;
	 font-weight: 600;
}
 .rf-original-box .rich-text h4 {
	 margin-bottom: 10px;
	 font-size: 20px;
	 line-height: 120%;
	 font-weight: 600;
}
 .rf-original-box .rich-text h5 {
	 margin-bottom: 10px;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 120%;
	 font-weight: 700;
}
 .rf-original-box .rich-text h6 {
	 margin-bottom: 10px;
	 color: #04243a;
	 font-size: 16px;
	 line-height: 120%;
	 font-weight: 800;
}
 .rf-original-box .rich-text p {
	 margin-bottom: 20px;
	 font-size: 18px;
	 line-height: 140%;
}
 .rf-original-box .rich-text strong {
	 font-weight: 600;
}
 .rf-original-box .rich-text a {
	 color: #316adb;
	 font-size: 18px;
	 font-weight: 500;
}
 .rf-original-box .rich-text blockquote {
	 margin-bottom: 20px;
	 border-left-color: #27806e;
	 line-height: 150%;
}
 .rf-original-box .rich-text img {
	 border-radius: 10px;
}
 .rf-original-box .rich-text figure {
	 margin-right: 0px;
	 padding-right: 30px;
	 padding-left: 30px;
}
 .rf-original-box .rich-text figcaption {
	 margin-top: 10px;
	 color: #000;
	 font-size: 16px;
	 line-height: 130%;
	 font-weight: 300;
}
 .rf-original-box .rich-text ul {
	 max-width: 600px;
	 margin-top: 30px;
	 margin-bottom: 30px;
	 padding-left: 60px;
	 color: #000;
	 font-size: 16px;
	 line-height: 140%;
}
 .rf-original-box .rich-text li {
	 margin-bottom: 15px;
}
 .rf-original-box .rich-text ol {
	 margin-top: 30px;
	 margin-bottom: 30px;
	 padding-left: 60px;
	 color: #000;
	 font-size: 16px;
	 line-height: 140%;
}
 .rf-original-box .div-block-24 {
	 margin-top: 118px;
}
 .rf-original-box .blog-link {
	 width: 100%;
	 text-decoration: none;
}
 .rf-original-box .blog-section__blog-link {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 height: 100%;
	 padding: 40px 20px;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 text-decoration: none;
}
 .rf-original-box .blog-section__blog-link.w--current {
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: stretch;
	 -webkit-align-items: stretch;
	 -ms-flex-align: stretch;
	 align-items: stretch;
}
 .rf-original-box .blog-read-link__bg {
	 position: absolute;
	 left: -5%;
	 top: 0%;
	 right: -5%;
	 bottom: 0%;
	 border-style: solid;
	 border-width: 1px;
	 border-color: #d9e6e0;
	 border-radius: 8px;
	 background-color: #f7f9f8;
}
 .rf-original-box .fs-section {
	 display: none;
}
 .rf-original-box .div-block-25 {
	 -webkit-box-flex: 1;
	 -webkit-flex: 1;
	 -ms-flex: 1;
	 flex: 1;
}
 .rf-original-box .blog-references-section {
	 width: 100%;
}
 .rf-original-box .div-block-26 {
	 position: static;
	 left: 0%;
	 top: 0%;
	 right: 0%;
	 bottom: auto;
	 z-index: 1001;
	 display: block;
	 opacity: 1;
	 -o-object-fit: fill;
	 object-fit: fill;
}
 .rf-original-box .partner-logos {
	 display: none;
	 padding-bottom: 148px;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 grid-auto-columns: 1fr;
	 -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	 grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	 -ms-grid-rows: auto auto auto;
	 grid-template-rows: auto auto auto;
}
 .rf-original-box .partner-logo {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 200px;
	 height: 200px;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .partner-logo.logo--monks-hill {
	 width: 150px;
	 margin-left: 60px;
}
 .rf-original-box .partner-logo.logo--insignia {
	 width: 240px;
	 padding-top: 30px;
}
 .rf-original-box .partner-logo.logo--the-lo {
	 width: 270px;
	 padding-top: 10px;
}
 .rf-original-box .partner-logo.logo--amasia {
	 width: 280px;
	 padding-top: 40px;
}
 .rf-original-box .partner-logo.logo--nordstar {
	 width: 270px;
}
 .rf-original-box .partner-logo.logo--crossbeam {
	 width: 300px;
	 padding-top: 41px;
	 text-align: left;
}
 .rf-original-box .partner-logo.logo--january-capital {
	 width: 300px;
	 padding-top: 50px;
}
 .rf-original-box .partner-logo.logo--nord {
	 padding-top: 50px;
}
 .rf-original-box .contact-chinese {
	 padding: 90px 60px;
}
 .rf-original-box .cc__content {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .cc__heading {
	 max-width: 400px;
	 font-family: 'PT Serif', serif;
	 font-size: 40px;
	 line-height: 130%;
	 white-space: break-spaces;
}
 .rf-original-box .image-15 {
	 width: 100%;
	 float: none;
	 -o-object-fit: cover;
	 object-fit: cover;
}
 .rf-original-box .btn--secondary {
	 min-width: 208px;
	 padding: 17px 27px;
	 border-style: solid;
	 border-width: 2px;
	 border-color: #04243a;
	 border-top-right-radius: 16px;
	 border-bottom-left-radius: 16px;
	 border-bottom-right-radius: 16px;
	 background-color: #d6e2dd;
	 font-family: 'Open Sans', sans-serif;
	 color: #04243a;
	 font-size: 18px;
	 line-height: 22px;
	 font-weight: 600;
	 text-align: center;
}
 .rf-original-box .btn--secondary:hover {
	 background-color: rgba(167, 188, 188, 0.45);
}
 .rf-original-box .btn--secondary.mobile-version-hero {
	 margin-top: 104px;
	 margin-bottom: 205px;
	 font-size: 18px;
}
 .rf-original-box .btn--secondary.how-it-works {
	 margin-top: 104px;
	 margin-bottom: 140px;
}
 .rf-original-box .btn--secondary.mobile-version-hero {
	 display: none;
	 margin-top: 104px;
	 margin-bottom: 205px;
	 font-size: 18px;
}
 .rf-original-box .btn--secondary.website-version-hero {
	 margin-bottom: 104px;
	 font-size: 18px;
}
 .rf-original-box .btn--secondary.website-version-hero.button--no-margin {
	 margin-bottom: 0px;
}
 .rf-original-box .btn--secondary.referal {
	 min-width: 200px;
}
 .rf-original-box .btn-wrapper {
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .btn-wrap {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
}
 .rf-original-box .btn-space {
	 width: 3px;
}
 .rf-original-box .hero-btns {
	 margin-bottom: 140px;
}
 .rf-original-box .hero-btns.phone {
	 display: none;
}
 .rf-original-box .how-work__spacer {
	 margin-top: 110px;
	 margin-bottom: 140px;
}
 .rf-original-box .footer__btns-wrap {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 width: 360px;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .consider__btn-wrap {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 470px;
	 -webkit-box-pack: start;
	 -webkit-justify-content: flex-start;
	 -ms-flex-pack: start;
	 justify-content: flex-start;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
}
 .rf-original-box .partner__slider {
	 width: 250px;
	 height: 300px;
	 margin-right: auto;
	 margin-left: auto;
	 background-color: transparent;
}
 .rf-original-box .partners__mask {
	 overflow: visible;
	 width: 250px;
}
 .rf-original-box .partners__slide {
	 height: 200px;
	 margin-right: 70px;
	 text-align: center;
}
 .rf-original-box .partners__slider-nav {
	 left: 0%;
	 top: auto;
	 right: 0%;
	 bottom: 10%;
}
 .rf-original-box .collection-list {
	 display: none;
}
 .rf-original-box .valuation {
	 height: 100vh;
	 max-height: 1000px;
	 padding-top: 70px;
	 padding-bottom: 80px;
	/*background-image: url('../images/Blue.png'), -webkit-gradient(linear, left top, left bottom, from(#04243a), to(#04243a));
	*/
	/*background-image: url('../images/Blue.png'), linear-gradient(180deg, #04243a, #04243a);
	*/
	 background-position: 50% 50%, 0px 0px;
	 background-size: cover, auto;
	 background-repeat: no-repeat, repeat;
}
 .rf-original-box .valuation__space-1 {
	 padding-bottom: 100px;
}
 .rf-original-box .valuation__heading1 {
	 max-width: 840px;
	 margin: 80px auto;
	 font-family: 'Cormorant Garamond', sans-serif;
	 color: #fff;
	 font-size: 58px;
	 line-height: 70px;
	 font-weight: 700;
	 text-align: center;
}
 .rf-original-box .valuation__calc {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 1148px;
	 min-height: 240px;
	 margin-right: auto;
	 margin-bottom: 88px;
	 margin-left: auto;
	 -webkit-box-orient: vertical;
	 -webkit-box-direction: normal;
	 -webkit-flex-direction: column;
	 -ms-flex-direction: column;
	 flex-direction: column;
	 -webkit-box-pack: center;
	 -webkit-justify-content: center;
	 -ms-flex-pack: center;
	 justify-content: center;
	 -webkit-box-align: center;
	 -webkit-align-items: center;
	 -ms-flex-align: center;
	 align-items: center;
	 border-radius: 24px;
	/*background-image: url('../images/Group-10.png'), -webkit-gradient(linear, left top, left bottom, from(#27806e), to(#27806e));
	*/
	/*background-image: url('../images/Group-10.png'), linear-gradient(180deg, #27806e, #27806e);
	*/
	 background-position: 50% 50%, 0px 0px;
	 background-size: 1036.21px 1036.21px, auto;
	 background-repeat: no-repeat, repeat;
	 text-align: center;
}
 .rf-original-box .valuation__heading2 {
	 margin-bottom: 24px;
	 font-family: Montserrat, sans-serif;
	 color: #fff;
	 font-size: 32px;
	 line-height: 45px;
	 font-weight: 700;
}
 .rf-original-box .valuation__btn {
	 min-width: 208px;
	 padding: 12px 16px;
	 border-radius: 8px;
	 background-color: #04243a;
	 font-family: Montserrat, sans-serif;
	 font-size: 16px;
	 line-height: 24px;
	 font-weight: 400;
	 text-align: center;
}
 .rf-original-box .valuation__btn:hover {
	 background-color: rgba(4, 36, 58, 0.9);
}
 .rf-original-box .valuation__btn.mobile-version-hero {
	 margin-top: 104px;
	 margin-bottom: 205px;
	 font-size: 18px;
}
 .rf-original-box .valuation__btn.how-it-works {
	 margin-top: 104px;
	 margin-bottom: 140px;
}
 .rf-original-box .valuation__btn.mobile-version-hero {
	 display: none;
	 margin-top: 104px;
	 margin-bottom: 205px;
	 font-size: 18px;
}
 .rf-original-box .valuation__btn.website-version-hero.button--no-margin {
	 margin-bottom: 0px;
}
 .rf-original-box .valuation__btn.referal {
	 min-width: 200px;
}
 .rf-original-box .valuation__logo-wrap {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -ms-flexbox;
	 display: flex;
	 max-width: 1148px;
	 margin-right: auto;
	 margin-left: auto;
	 -webkit-box-pack: justify;
	 -webkit-justify-content: space-between;
	 -ms-flex-pack: justify;
	 justify-content: space-between;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
}
 .rf-original-box .valuation__logo-content {
	 width: 23%;
	 text-align: center;
}
 .rf-original-box .valuation__logo-name {
	 margin-top: 25px;
	 margin-bottom: 16px;
	 color: #fff;
	 font-size: 18px;
	 line-height: 22px;
	 font-weight: 800;
}
 .rf-original-box .valuation__paragraph {
	 color: #fff;
	 font-size: 16px;
	 line-height: 24px;
	 font-weight: 400;
}
 .rf-original-box .margin-right--24 {
	 margin-left: 24px;
}
 @media screen and (min-width: 1920px) {
	 .rf-original-box .section.section--blog-post {
		 padding-right: 0px;
		 padding-left: 0px;
	}
	 .rf-original-box .blog-header.blog--heading-smaller {
		 font-size: 35px;
	}
	 .rf-original-box .cta--header3 {
		 font-size: 30px;
	}
	 .rf-original-box .author-name {
		 font-size: 24px;
	}
	 .rf-original-box .blog-post-header {
		 max-width: 900px;
		 font-size: 45px;
	}
	 .rf-original-box .html-embed {
		 position: static;
	}
	 .rf-original-box .partners__mask {
		 overflow: visible;
	}
}
 @media screen and (max-width: 991px) {
	 .rf-original-box h1 {
		 font-size: 60px;
		 line-height: 75px;
	}
	 .rf-original-box h2 {
		 font-size: 40px;
		 line-height: 52px;
	}
	 .rf-original-box .section.image-bg.hidden {
		 display: none;
	}
	 .rf-original-box .section.section--blogs-section {
		 padding-right: 30px;
		 padding-left: 30px;
	}
	 .rf-original-box .section.section--blogs-section.phone-slider {
		 display: none;
	}
	 .rf-original-box .section.margin--30px.margin-top {
		 margin-top: 150px;
	}
	 .rf-original-box .container.hero {
		 padding-right: 30px;
		 padding-left: 30px;
	}
	 .rf-original-box .btn--primary.mobile-version-hero {
		 margin-top: 0px;
		 margin-bottom: 70px;
	}
	 .rf-original-box .btn--primary.how-it-works {
		 margin-top: 56px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .btn--primary.mobile-version-hero {
		 margin-top: 0px;
		 margin-bottom: 70px;
	}
	 .rf-original-box .btn--primary.website-version-hero {
		 margin-top: 0px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .hero-content {
		 padding-top: 88px;
	}
	 .rf-original-box .legacy-ingo-grid {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .footer-container {
		 padding-top: 56px;
	}
	 .rf-original-box .link-container {
		 min-width: 250px;
	}
	 .rf-original-box .exit-heading {
		 font-size: 18px;
		 line-height: 22px;
	}
	 .rf-original-box .team-member-container {
		 margin-bottom: 56px;
	}
	 .rf-original-box .hero-info-container {
		 max-width: 500px;
	}
	 .rf-original-box .nav-link {
		 padding-right: 5px;
		 padding-left: 5px;
	}
	 .rf-original-box .nav-button.neutral.wide {
		 margin-right: auto;
		 margin-left: auto;
		 -webkit-box-pack: center;
		 -webkit-justify-content: center;
		 -ms-flex-pack: center;
		 justify-content: center;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .legacy-heading {
		 padding-top: 56px;
	}
	 .rf-original-box .legacy-info {
		 font-size: 16px;
	}
	 .rf-original-box .speach-bubble-text {
		 font-size: 35px;
		 line-height: 42px;
	}
	 .rf-original-box .exit-founder {
		 font-size: 14px;
		 line-height: 16px;
	}
	 .rf-original-box .logo-heading {
		 text-align: center;
	}
	 .rf-original-box .founders-heading {
		 padding-top: 56px;
	}
	 .rf-original-box .heading-6 {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .selling-heading {
		 padding-top: 56px;
	}
	 .rf-original-box .footer-paragraph {
		 padding-bottom: 56px;
		 font-size: 14px;
		 line-height: 17px;
	}
	 .rf-original-box .footer-link {
		 font-size: 14px;
		 line-height: 17px;
	}
	 .rf-original-box .hero-left {
		 margin-right: 23px;
	}
	 .rf-original-box .brand-fits-heading {
		 margin-bottom: 56px;
	}
	 .rf-original-box .brand-fits-bottom-header {
		 margin-top: 56px;
	}
	 .rf-original-box .brand-fits-container {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .footer-bottom {
		 padding-bottom: 56px;
	}
	 .rf-original-box .logo-container {
		 padding-bottom: 148px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .subpage-heading {
		 margin-bottom: 56px;
		 padding-top: 88px;
	}
	 .rf-original-box .subpage-heading.refer {
		 margin-left: 0px;
		 text-align: left;
	}
	 .rf-original-box .working-paragraph {
		 padding-bottom: 22px;
	}
	 .rf-original-box .offer-text {
		 max-width: none;
		 padding-bottom: 22px;
	}
	 .rf-original-box .offer-container {
		 padding-right: 0px;
		 padding-left: 0px;
	}
	 .rf-original-box .offer-title {
		 margin-bottom: 56px;
	}
	 .rf-original-box .cookie-policy-container {
		 padding-top: 0px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .drives-block {
		 margin-bottom: 56px;
	}
	 .rf-original-box .heading-17 {
		 padding-top: 56px;
		 padding-bottom: 45px;
	}
	 .rf-original-box .hero-paragraph {
		 max-width: 500px;
	}
	 .rf-original-box .exit-container {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .heading-19 {
		 margin-bottom: 56px;
	}
	 .rf-original-box .header-section.image-bg {
		 padding-top: 60px;
	}
	 .rf-original-box .exit-text {
		 font-size: 16px;
		 line-height: 26px;
	}
	 .rf-original-box .primary-button-white.padding {
		 margin-top: 22px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .primary-button-white.brand-selling {
		 margin-top: 56px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .footer-button {
		 min-width: 170px;
	}
	 .rf-original-box .mouse-icon-holder {
		 display: none;
	}
	 .rf-original-box .checkbox {
		 border-radius: 0px 100px 100px;
	}
	 .rf-original-box .moneybage-holder {
		 bottom: 8px;
		 width: 20px;
		 height: 30px;
	}
	 .rf-original-box .insignia-logo {
		 margin-right: 0px;
		 margin-bottom: 30px;
	}
	 .rf-original-box .referal-container {
		 -webkit-box-align: start;
		 -webkit-align-items: flex-start;
		 -ms-flex-align: start;
		 align-items: flex-start;
	}
	 .rf-original-box .referal-box {
		 margin-bottom: 56px;
		 padding: 24px 32px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: end;
		 -webkit-align-items: flex-end;
		 -ms-flex-align: end;
		 align-items: flex-end;
	}
	 .rf-original-box .moneybag-wrapper {
		 margin-top: 6px;
	}
	 .rf-original-box .referal-paragraph {
		 margin-right: 0px;
		 margin-bottom: 24px;
		 font-size: 16px;
		 line-height: 26px;
	}
	 .rf-original-box .referal-top-conatiner {
		 -webkit-box-align: start;
		 -webkit-align-items: flex-start;
		 -ms-flex-align: start;
		 align-items: flex-start;
	}
	 .rf-original-box .as-seen-on-heading {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .right-arrow {
		 left: auto;
		 top: 370px;
		 right: 0%;
		 bottom: 0%;
		 display: block;
		 height: 50px;
	}
	 .rf-original-box .slider {
		 margin-right: 0px;
		 margin-left: 0px;
	}
	 .rf-original-box .left-arrow {
		 left: 0%;
		 top: 370px;
		 right: auto;
		 bottom: 0%;
		 display: block;
		 height: 50px;
	}
	 .rf-original-box .slide {
		 width: 48vw;
		 margin-right: 1vw;
		 margin-left: 1vw;
	}
	 .rf-original-box .slide.first {
		 width: 48vw;
		 margin-left: 1vw;
	}
	 .rf-original-box .slide.last {
		 margin-right: 1vw;
	}
	 .rf-original-box .as-seen-on-slider-container {
		 width: 100vw;
	}
	 .rf-original-box .c-i--blogs-page {
		 margin-bottom: 90px;
	}
	 .rf-original-box .blog-thumbnail {
		 width: 340px;
		 height: 240px;
	}
	 .rf-original-box .blog-header {
		 font-size: 30px;
	}
	 .rf-original-box .blog__content-wrapper {
		 -webkit-box-flex: 0;
		 -webkit-flex: 0 auto;
		 -ms-flex: 0 auto;
		 flex: 0 auto;
	}
	 .rf-original-box .blog-date-wrapper.auto-width {
		 max-width: none;
	}
	 .rf-original-box .blog-bottom-line {
		 width: 100%;
		 margin-top: 30px;
	}
	 .rf-original-box .author-hero-wrapper {
		 display: none;
		 margin-left: 30px;
		 padding-right: 20px;
		 padding-left: 20px;
	}
	 .rf-original-box .blog-post-section {
		 display: block;
	}
	 .rf-original-box .blog-section__collection-item {
		 width: 40%;
		 max-width: none;
		 margin-right: 0px;
		 margin-bottom: 0px;
	}
	 .rf-original-box .blog-section__collection-list {
		 grid-auto-columns: 1fr;
		 grid-column-gap: 30px;
		 grid-row-gap: 30px;
		 -ms-grid-columns: 1fr 1fr;
		 grid-template-columns: 1fr 1fr;
		 -ms-grid-rows: auto auto;
		 grid-template-rows: auto auto;
	}
	 .rf-original-box .blog-post-header {
		 font-size: 35px;
	}
	 .rf-original-box .div-center {
		 display: -webkit-box;
		 display: -webkit-flex;
		 display: -ms-flexbox;
		 display: flex;
		 margin-top: 10px;
		 -webkit-box-pack: center;
		 -webkit-justify-content: center;
		 -ms-flex-pack: center;
		 justify-content: center;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .rich-text figure {
		 margin-right: 0px;
	}
	 .rf-original-box .blog-section__blog-link {
		 width: 100%;
		 min-height: 400px;
		 padding-top: 20px;
		 padding-bottom: 20px;
		 -webkit-box-align: stretch;
		 -webkit-align-items: stretch;
		 -ms-flex-align: stretch;
		 align-items: stretch;
	}
	 .rf-original-box .blog-section__blog-link.ipad {
		 height: 100%;
		 min-height: auto;
		 padding: 30px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-pack: justify;
		 -webkit-justify-content: space-between;
		 -ms-flex-pack: justify;
		 justify-content: space-between;
		 -webkit-box-align: stretch;
		 -webkit-align-items: stretch;
		 -ms-flex-align: stretch;
		 align-items: stretch;
	}
	 .rf-original-box .blog-read-link__bg {
		 display: none;
		 opacity: 1;
	}
	 .rf-original-box .fs-section {
		 display: block;
		 overflow: hidden;
		 margin-top: 120px;
		 margin-bottom: 120px;
		 padding-right: 30px;
		 padding-bottom: 0px;
		 padding-left: 30px;
	}
	 .rf-original-box .fs-section.home-blog-hidden {
		 display: none;
	}
	 .rf-original-box .fs-collection-wrapper {
		 height: 100%;
	}
	 .rf-original-box .fs-collection-wrapper.fs-dynamic-feed {
		 display: none;
	}
	 .rf-original-box .fs-collection-list {
		 height: 100%;
	}
	 .rf-original-box .fs-collection-item {
		 width: 100%;
		 height: 100%;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .fs-slide-content {
		 padding: 60px 60px 60px 30px;
		 text-decoration: none;
	}
	 .rf-original-box .fs-dynamic-feed {
		 display: none;
	}
	 .rf-original-box .fs-slider {
		 overflow: hidden;
		 height: auto;
		 margin-top: 30px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
		 background-color: rgba(221, 221, 221, 0);
	}
	 .rf-original-box .fs-mask {
		 overflow: hidden;
		 height: auto;
		 padding: 5px 30px;
	}
	 .rf-original-box .fs-slide {
		 margin-right: 60px;
		 border-style: solid;
		 border-width: 1px;
		 border-color: #f7f9f8;
		 border-radius: 10px;
		 box-shadow: 0 0 10px 0 #d6e2dd;
	}
	 .rf-original-box .fs-slide.fs-slide--white {
		 background-color: #fff;
	}
	 .rf-original-box .slider-arrow {
		 position: absolute;
		 left: 0%;
		 top: auto;
		 right: auto;
		 bottom: -50px;
		 display: none;
		 overflow: visible;
		 color: #3f3f3f;
	}
	 .rf-original-box .slider-arrow--1 {
		 left: auto;
		 top: auto;
		 right: 0%;
		 bottom: -50px;
		 display: none;
		 overflow: visible;
		 color: #3f3f3f;
	}
	 .rf-original-box .slide-nav-2 {
		 position: static;
		 display: -webkit-box;
		 display: -webkit-flex;
		 display: -ms-flexbox;
		 display: flex;
		 overflow: visible;
		 height: 40px;
		 margin-top: 10px;
		 margin-right: 30px;
		 margin-left: 30px;
		 padding-top: 10px;
		 -webkit-box-pack: center;
		 -webkit-justify-content: center;
		 -ms-flex-pack: center;
		 justify-content: center;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
		 border-radius: 15px;
		 background-color: transparent;
		 color: #04243a;
		 font-size: 18px;
	}
	 .rf-original-box .header-limit {
		 margin-bottom: 15px;
		 font-family: Montserrat, sans-serif;
		 color: #27806e;
		 font-size: 30px;
		 line-height: 120%;
		 font-weight: 500;
	}
	 .rf-original-box .paragraph-limit {
		 color: #04243a;
		 font-size: 18px;
		 line-height: 130%;
	}
	 .rf-original-box .partner-logos {
		 max-width: 800px;
		 margin-right: auto;
		 margin-left: auto;
		 -ms-grid-rows: auto auto auto auto;
		 grid-template-rows: auto auto auto auto;
	}
	 .rf-original-box .partner-logo.logo--insignia {
		 width: 250px;
	}
	 .rf-original-box .partner-logo.logo--the-lo {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--amasia {
		 width: 270px;
	}
	 .rf-original-box .partner-logo.logo--crossbeam {
		 width: 270px;
	}
	 .rf-original-box .partner-logo.logo--january-capital {
		 width: 270px;
	}
	 .rf-original-box .cc__heading {
		 padding-right: 40px;
		 font-size: 40px;
	}
	 .rf-original-box .image-16 {
		 text-align: left;
	}
	 .rf-original-box .btn--secondary.mobile-version-hero {
		 margin-top: 0px;
		 margin-bottom: 70px;
	}
	 .rf-original-box .btn--secondary.how-it-works {
		 margin-top: 56px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .btn--secondary.mobile-version-hero {
		 margin-top: 0px;
		 margin-bottom: 70px;
	}
	 .rf-original-box .btn--secondary.website-version-hero {
		 margin-top: 0px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .btn-wrap {
		 width: auto;
		 min-height: 140px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: start;
		 -webkit-align-items: flex-start;
		 -ms-flex-align: start;
		 align-items: flex-start;
	}
	 .rf-original-box .valuation__calc {
		 margin-right: 30px;
		 margin-left: 30px;
		 padding-right: 15px;
		 padding-left: 15px;
	}
	 .rf-original-box .valuation__btn.mobile-version-hero {
		 margin-top: 0px;
		 margin-bottom: 70px;
	}
	 .rf-original-box .valuation__btn.how-it-works {
		 margin-top: 56px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .valuation__btn.mobile-version-hero {
		 margin-top: 0px;
		 margin-bottom: 70px;
	}
	 .rf-original-box .valuation__btn.website-version-hero {
		 margin-top: 0px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .valuation__logo-wrap {
		 padding-right: 15px;
		 padding-left: 15px;
	}
	 .rf-original-box .valuation__paragraph {
		 font-size: 14px;
		 line-height: 20px;
	}
}
 @media screen and (max-width: 767px) {
	 .rf-original-box h1 {
		 font-size: 40px;
		 line-height: 48px;
	}
	 .rf-original-box h2 {
		 font-size: 32px;
		 line-height: 39px;
	}
	 .rf-original-box .section.blue-bg {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .section.blue-bg.img-bg-on-blue {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .section.mint-bg {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .section.green-bg {
		 margin-left: 0px;
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .section.image-bg-blue {
		 padding-right: 32px;
		 padding-left: 32px;
		 background-size: auto;
	}
	 .rf-original-box .section.section--blogs-page {
		 padding-right: 30px;
		 padding-left: 30px;
	}
	 .rf-original-box .section.margin--30px {
		 padding-right: 30px;
		 padding-left: 30px;
	}
	 .rf-original-box .container.hero {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .btn--primary {
		 width: 100%;
		 text-align: center;
	}
	 .rf-original-box .btn--primary.how-it-works {
		 margin-top: 48px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .btn--primary.mobile-version-hero {
		 display: block;
		 margin-top: 54px;
		 margin-bottom: 0px;
	}
	 .rf-original-box .btn--primary.website-version-hero {
		 display: none;
	}
	 .rf-original-box .btn--primary.website-version-hero.button--no-margin {
		 display: inline;
	}
	 .rf-original-box .hero-content {
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .legacy-ingo-grid {
		 padding-top: 32px;
		 padding-bottom: 56px;
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto auto;
		 grid-template-rows: auto auto auto auto;
	}
	 .rf-original-box .exit-grid {
		 padding-bottom: 56px;
		 grid-row-gap: 48px;
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto auto;
		 grid-template-rows: auto auto auto auto;
	}
	 .rf-original-box .founders-grid {
		 padding-bottom: 61px;
		 grid-row-gap: 80px;
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto;
		 grid-template-rows: auto auto auto;
	}
	 .rf-original-box .footer-container {
		 padding-top: 40px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .link-container {
		 padding-bottom: 40px;
		 justify-items: start;
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto auto auto auto;
		 grid-template-rows: auto auto auto auto auto auto;
	}
	 .rf-original-box .team-member-container {
		 margin-bottom: 0px;
	}
	 .rf-original-box .team-member-text {
		 max-width: 252px;
		 margin-right: auto;
		 margin-left: auto;
	}
	 .rf-original-box .hero-heading {
		 line-height: 75px;
	}
	 .rf-original-box .nav-link {
		 margin-left: 8px;
		 -webkit-box-orient: horizontal;
		 -webkit-box-direction: reverse;
		 -webkit-flex-direction: row-reverse;
		 -ms-flex-direction: row-reverse;
		 flex-direction: row-reverse;
		 -webkit-box-pack: end;
		 -webkit-justify-content: flex-end;
		 -ms-flex-pack: end;
		 justify-content: flex-end;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
		 background-color: #d6e2dd;
		 color: #04243a;
		 text-align: left;
	}
	 .rf-original-box .nav-button {
		 position: static;
		 display: inline-block;
		 margin-right: 16px;
		 text-align: left;
	}
	 .rf-original-box .nav-button.neutral.wide {
		 display: block;
		 text-align: center;
	}
	 .rf-original-box .legacy-heading {
		 padding-top: 56px;
	}
	 .rf-original-box .exit-speack-bubble {
		 padding: 16px 24px 24px;
	}
	 .rf-original-box .speach-bubble-text {
		 font-size: 16px;
		 line-height: 26px;
	}
	 .rf-original-box .exit-founder {
		 margin-top: 8px;
		 font-size: 12px;
		 line-height: 15px;
	}
	 .rf-original-box .founders-heading {
		 padding-top: 56px;
	}
	 .rf-original-box .heading-6 {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .selling-heading {
		 padding-top: 80px;
	}
	 .rf-original-box .footer-paragraph {
		 padding-top: 9px;
		 padding-bottom: 40px;
	}
	 .rf-original-box .footer-copyright {
		 margin-top: 56px;
	}
	 .rf-original-box .brand-fits-heading {
		 margin-bottom: 56px;
		 text-align: left;
	}
	 .rf-original-box .brand-fit-heading {
		 margin-right: auto;
		 margin-left: auto;
	}
	 .rf-original-box .brand-fit-paragraph {
		 margin-right: auto;
		 margin-left: auto;
	}
	 .rf-original-box .brand-fits-container {
		 padding-top: 56px;
		 padding-bottom: 56px;
		 -webkit-box-align: start;
		 -webkit-align-items: flex-start;
		 -ms-flex-align: start;
		 align-items: flex-start;
	}
	 .rf-original-box .footer-bottom {
		 padding-bottom: 40px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: reverse;
		 -webkit-flex-direction: column-reverse;
		 -ms-flex-direction: column-reverse;
		 flex-direction: column-reverse;
		 -webkit-box-align: start;
		 -webkit-align-items: flex-start;
		 -ms-flex-align: start;
		 align-items: flex-start;
	}
	 .rf-original-box .subpage-heading {
		 margin-bottom: 40px;
		 line-height: 75px;
	}
	 .rf-original-box .working-grid {
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto;
		 grid-template-rows: auto auto;
	}
	 .rf-original-box .working-paragraph {
		 padding-top: 8px;
		 padding-bottom: 24px;
	}
	 .rf-original-box .working-title {
		 padding-top: 180px;
		 padding-bottom: 24px;
	}
	 .rf-original-box .offer-grid {
		 grid-template-areas: "Area-3" "Area-4" "Area-5" "Area" "Area-2";
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto auto auto;
		 grid-template-rows: auto auto auto auto auto;
	}
	 .rf-original-box .offer-text {
		 max-width: 194px;
	}
	 .rf-original-box .offer-title {
		 margin-bottom: 40px;
	}
	 .rf-original-box .cookie-policy-container {
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .team-hero-left {
		 margin-bottom: 24px;
		 padding-right: 0px;
	}
	 .rf-original-box .div-block-23 {
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto;
		 grid-template-rows: auto auto auto;
	}
	 .rf-original-box .drives-block {
		 margin-bottom: 24px;
	}
	 .rf-original-box .heading-17 {
		 padding-bottom: 33px;
	}
	 .rf-original-box .hero-paragraph {
		 padding-top: 16px;
		 padding-bottom: 24px;
		 font-size: 16px;
		 line-height: 24px;
	}
	 .rf-original-box .exit-container {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .heading-19 {
		 margin-bottom: 40px;
		 text-align: left;
	}
	 .rf-original-box .header-section.image-bg {
		 display: block;
	}
	 .rf-original-box .round-image-holder {
		 max-height: 32px;
		 max-width: 32px;
		 margin-right: 8px;
	}
	 .rf-original-box .primary-button-white.padding {
		 -webkit-align-self: stretch;
		 -ms-flex-item-align: stretch;
		 -ms-grid-row-align: stretch;
		 align-self: stretch;
		 text-align: center;
	}
	 .rf-original-box .primary-button-white.brand-selling {
		 display: block;
		 min-width: 230px;
		 margin-top: 64px;
		 margin-bottom: 0px;
		 text-align: center;
	}
	 .rf-original-box .primary-button-white.brand-selling.secondary {
		 margin-top: 30px;
	}
	 .rf-original-box .founders-paragraph {
		 max-width: 345px;
		 padding-top: 14px;
	}
	 .rf-original-box .brand {
		 padding-left: 0px;
	}
	 .rf-original-box .brand.w--current {
		 padding-left: 0px;
	}
	 .rf-original-box .website-version-this-could-be-you-container {
		 display: none;
	}
	 .rf-original-box .hero-right {
		 margin-right: auto;
		 margin-left: auto;
	}
	 .rf-original-box .couldbeyou {
		 margin-top: -32px;
		 margin-left: 130px;
		 padding-bottom: 0px;
	}
	 .rf-original-box .brand-fit-image-container {
		 max-width: 345px;
		 margin-right: auto;
		 margin-left: auto;
	}
	 .rf-original-box .top-image-container {
		 margin-bottom: -127px;
	}
	 .rf-original-box .selling-container {
		 padding-bottom: 80px;
	}
	 .rf-original-box .partner-container {
		 display: -webkit-box;
		 display: -webkit-flex;
		 display: -ms-flexbox;
		 display: flex;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .mobile-version-this-could-be-you-container {
		 display: block;
	}
	 .rf-original-box ._32space {
		 display: block;
		 height: 32px;
	}
	 .rf-original-box .brand-fit-grid {
		 margin-right: auto;
		 margin-left: auto;
		 grid-row-gap: 48px;
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto;
		 grid-template-rows: auto auto auto;
	}
	 .rf-original-box .menu-button {
		 background-color: transparent;
	}
	 .rf-original-box .menu-button.w--open {
		 background-color: #d6e2dd;
		 color: #04243a;
	}
	 .rf-original-box .nav-menu {
		 position: absolute;
		 -webkit-box-pack: start;
		 -webkit-justify-content: flex-start;
		 -ms-flex-pack: start;
		 justify-content: flex-start;
		 -webkit-box-align: stretch;
		 -webkit-align-items: stretch;
		 -ms-flex-align: stretch;
		 align-items: stretch;
		 background-color: #d6e2dd;
		 text-align: left;
	}
	 .rf-original-box .icon {
		 color: #04243a;
	}
	 .rf-original-box .modal {
		 margin-right: 32px;
		 margin-left: 32px;
		 padding: 32px 56px;
	}
	 .rf-original-box .moneybage-holder {
		 bottom: 2px;
		 width: 14px;
		 height: 25px;
		 margin-left: 3px;
	}
	 .rf-original-box .cookie-modal {
		 -webkit-box-align: end;
		 -webkit-align-items: flex-end;
		 -ms-flex-align: end;
		 align-items: flex-end;
	}
	 .rf-original-box .cookie-text {
		 padding-right: 12px;
		 padding-left: 12px;
	}
	 .rf-original-box .referal-paragraph {
		 margin-left: 24px;
	}
	 .rf-original-box .blank {
		 display: block;
	}
	 .rf-original-box .blank.hidden {
		 display: none;
	}
	 .rf-original-box .language-container.nav {
		 -webkit-box-orient: horizontal;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: row;
		 -ms-flex-direction: row;
		 flex-direction: row;
	}
	 .rf-original-box .nav-droplet {
		 margin-right: 6px;
		 margin-bottom: 4px;
	}
	 .rf-original-box .lauguage-dropdown-balloon.nav {
		 position: static;
	}
	 .rf-original-box .as-seen-on-heading {
		 padding-top: 56px;
		 padding-bottom: 56px;
	}
	 .rf-original-box .right-arrow {
		 top: 400px;
	}
	 .rf-original-box .slider {
		 height: 340px;
	}
	 .rf-original-box .left-arrow {
		 top: 400px;
	}
	 .rf-original-box .blog-flex {
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: reverse;
		 -webkit-flex-direction: column-reverse;
		 -ms-flex-direction: column-reverse;
		 flex-direction: column-reverse;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .blog-thumbnail {
		 width: auto;
		 margin-bottom: 30px;
		 margin-left: 0px;
	}
	 .rf-original-box .blog-content {
		 width: 100%;
	}
	 .rf-original-box .blog-sub-title.lighter {
		 font-size: 15px;
	}
	 .rf-original-box .blog-header {
		 font-size: 30px;
	}
	 .rf-original-box .blog-paragraph {
		 font-size: 16px;
	}
	 .rf-original-box .blog__content-wrapper {
		 -webkit-box-flex: 1;
		 -webkit-flex: 1;
		 -ms-flex: 1;
		 flex: 1;
	}
	 .rf-original-box .cta--wrapper {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .cta--header2 {
		 font-size: 35px;
		 line-height: 120%;
	}
	 .rf-original-box .cta--header3 {
		 font-size: 24px;
	}
	 .rf-original-box .margin-top--40px {
		 margin-top: 60px;
	}
	 .rf-original-box .blog-post__wrapper {
		 padding-bottom: 240px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .blog-post__content {
		 margin-bottom: 30px;
	}
	 .rf-original-box .author-hero-wrapper {
		 margin-left: 0px;
	}
	 .rf-original-box .author-image {
		 width: 100px;
		 height: 100px;
	}
	 .rf-original-box .blog-main-image {
		 margin-top: -120px;
	}
	 .rf-original-box .image-soft-corner {
		 border-radius: 10px;
	}
	 .rf-original-box .image-14 {
		 width: 100px;
		 height: 100px;
		 border-top-left-radius: 100px;
		 border-bottom-left-radius: 100px;
		 border-bottom-right-radius: 100px;
	}
	 .rf-original-box .heading2 {
		 font-size: 45px;
	}
	 .rf-original-box .blog-post-header {
		 font-size: 30px;
	}
	 .rf-original-box .rich-text h1 {
		 font-size: 35px;
	}
	 .rf-original-box .rich-text h2 {
		 font-size: 30px;
	}
	 .rf-original-box .rich-text h3 {
		 font-size: 25px;
	}
	 .rf-original-box .rich-text p {
		 font-size: 16px;
	}
	 .rf-original-box .rich-text figure {
		 margin-right: 0px;
		 padding-right: 20px;
		 padding-left: 20px;
	}
	 .rf-original-box .blog-section__blog-link.ipad {
		 -webkit-box-flex: 1;
		 -webkit-flex: 1;
		 -ms-flex: 1;
		 flex: 1;
	}
	 .rf-original-box .fs-section {
		 padding-right: 15px;
		 padding-left: 15px;
	}
	 .rf-original-box .fs-collection-list {
		 display: -webkit-box;
		 display: -webkit-flex;
		 display: -ms-flexbox;
		 display: flex;
		 -webkit-box-orient: horizontal;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: row;
		 -ms-flex-direction: row;
		 flex-direction: row;
		 -webkit-box-align: stretch;
		 -webkit-align-items: stretch;
		 -ms-flex-align: stretch;
		 align-items: stretch;
	}
	 .rf-original-box .fs-collection-item {
		 display: block;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .fs-slider {
		 margin-top: 30px;
	}
	 .rf-original-box .fs-mask {
		 padding: 5px 15px;
	}
	 .rf-original-box .slide-nav-2 {
		 height: 30px;
		 margin-right: 15px;
		 margin-left: 15px;
	}
	 .rf-original-box .header-limit {
		 font-size: 24px;
	}
	 .rf-original-box .partner-logos {
		 max-width: none;
		 min-width: 70%;
		 margin-right: 0px;
		 margin-left: 0px;
		 justify-items: center;
		 -ms-grid-columns: 1fr 1fr 1fr 1fr;
		 grid-template-columns: 1fr 1fr 1fr 1fr;
		 -ms-grid-rows: auto auto auto auto auto;
		 grid-template-rows: auto auto auto auto auto;
	}
	 .rf-original-box .partner-logo {
		 width: 150px;
		 height: 150px;
	}
	 .rf-original-box .partner-logo.logo--monks-hill {
		 width: 100px;
	}
	 .rf-original-box .partner-logo.logo--insignia {
		 width: 180px;
	}
	 .rf-original-box .partner-logo.logo--the-lo {
		 width: 180px;
	}
	 .rf-original-box .partner-logo.logo--amasia {
		 width: 180px;
	}
	 .rf-original-box .partner-logo.logo--nordstar {
		 width: 250px;
	}
	 .rf-original-box .partner-logo.logo--january-capital {
		 width: 250px;
	}
	 .rf-original-box .contact-chinese {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .cc__content {
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .cc__heading {
		 margin-bottom: 30px;
		 padding-right: 0px;
		 font-size: 30px;
	}
	 .rf-original-box .image-18 {
		 text-align: center;
	}
	 .rf-original-box .btn--secondary {
		 width: 100%;
		 text-align: center;
	}
	 .rf-original-box .btn--secondary.how-it-works {
		 margin-top: 48px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .btn--secondary.mobile-version-hero {
		 display: block;
		 margin-top: 54px;
		 margin-bottom: 0px;
	}
	 .rf-original-box .btn--secondary.website-version-hero {
		 display: none;
	}
	 .rf-original-box .btn--secondary.website-version-hero.button--no-margin {
		 display: inline;
	}
	 .rf-original-box .btn-wrap {
		 width: auto;
		 min-height: 150px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .hero-btns.desktop {
		 display: none;
	}
	 .rf-original-box .hero-btns.phone {
		 display: block;
		 margin-top: 40px;
		 margin-bottom: 0px;
	}
	 .rf-original-box .consider__btn-wrap {
		 max-width: none;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
	}
	 .rf-original-box .partner__slider {
		 width: 92vw;
		 height: 250px;
	}
	 .rf-original-box .partners__mask {
		 width: 180px;
	}
	 .rf-original-box .partners__slide {
		 height: 160px;
		 margin-right: 30px;
	}
	 .rf-original-box .helper-center {
		 margin-right: auto;
		 margin-left: auto;
	}
	 .rf-original-box .valuation {
		 height: auto;
		 max-height: none;
	}
	 .rf-original-box .valuation__heading1 {
		 margin-bottom: 60px;
		 font-size: 40px;
		 line-height: 50px;
	}
	 .rf-original-box .valuation__calc {
		 min-height: 190px;
		 margin-bottom: 30px;
	}
	 .rf-original-box .valuation__heading2 {
		 font-size: 24px;
		 line-height: 33px;
	}
	 .rf-original-box .valuation__btn.how-it-works {
		 margin-top: 48px;
		 margin-bottom: 56px;
	}
	 .rf-original-box .valuation__btn.mobile-version-hero {
		 display: block;
		 margin-top: 54px;
		 margin-bottom: 0px;
	}
	 .rf-original-box .valuation__btn.website-version-hero {
		 display: none;
	}
	 .rf-original-box .valuation__btn.website-version-hero.button--no-margin {
		 display: inline;
	}
	 .rf-original-box .valuation__logo-wrap {
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: center;
		 -webkit-align-items: center;
		 -ms-flex-align: center;
		 align-items: center;
	}
	 .rf-original-box .valuation__logo-content {
		 width: auto;
		 margin-bottom: 30px;
	}
}
 @media screen and (max-width: 479px) {
	 .rf-original-box .section {
		 padding-right: 32px;
		 padding-left: 32px;
	}
	 .rf-original-box .section.section--blog-post {
		 padding-right: 15px;
		 padding-left: 15px;
	}
	 .rf-original-box .section.section--blogs-page {
		 padding-right: 15px;
		 padding-left: 15px;
	}
	 .rf-original-box .section.margin--30px {
		 padding-right: 15px;
		 padding-left: 15px;
	}
	 .rf-original-box .container.hero {
		 overflow: hidden;
	}
	 .rf-original-box .hero-heading {
		 line-height: 75px;
	}
	 .rf-original-box .logo-heading {
		 font-size: 28px;
		 font-weight: 700;
	}
	 .rf-original-box .subpage-heading {
		 text-align: left;
	}
	 .rf-original-box .subpage-heading.refer {
		 font-size: 25px;
	}
	 .rf-original-box .working-title {
		 padding-top: 104px;
	}
	 .rf-original-box .team-hero-right {
		 font-size: 16px;
		 line-height: 24px;
	}
	 .rf-original-box .team-hero-left {
		 font-size: 16px;
		 line-height: 24px;
	}
	 .rf-original-box .drives-paragraph {
		 font-size: 16px;
	}
	 .rf-original-box .heading-19 {
		 max-width: 300px;
	}
	 .rf-original-box .footer-button {
		 min-width: 200px;
	}
	 .rf-original-box .couldbeyou {
		 margin-left: 69px;
	}
	 .rf-original-box .top-image-container {
		 margin-bottom: -79px;
	}
	 .rf-original-box .valuation-modal-wrapper {
		 display: none;
	}
	 .rf-original-box .modal {
		 margin-right: 20px;
		 margin-left: 20px;
		 padding-right: 36px;
		 padding-left: 36px;
	}
	 .rf-original-box .text-field {
		 margin-top: 12px;
		 margin-bottom: 12px;
	}
	 .rf-original-box .paragraph-9 {
		 margin-top: 12px;
		 margin-bottom: 12px;
	}
	 .rf-original-box .close-modal {
		 display: none;
	}
	 .rf-original-box .cookie-text {
		 padding-left: 0px;
		 font-size: 12px;
		 line-height: 16px;
	}
	 .rf-original-box .referal-box {
		 padding: 18px 24px;
	}
	 .rf-original-box .slide {
		 width: 98vw;
	}
	 .rf-original-box .slide.first {
		 width: 98vw;
	}
	 .rf-original-box .blog-sub-title.lighter {
		 font-size: 16px;
	}
	 .rf-original-box .blog-header {
		 font-size: 26px;
	}
	 .rf-original-box .blog-paragraph {
		 -webkit-box-flex: 1;
		 -webkit-flex: 1;
		 -ms-flex: 1;
		 flex: 1;
	}
	 .rf-original-box .cta--wrapper {
		 padding: 60px 20px;
	}
	 .rf-original-box .cta--header2 {
		 font-size: 30px;
	}
	 .rf-original-box .cta--header3 {
		 font-size: 20px;
	}
	 .rf-original-box .blog-post__wrapper {
		 padding-top: 60px;
		 padding-bottom: 120px;
	}
	 .rf-original-box .author-links-wrapper {
		 -webkit-box-pack: center;
		 -webkit-justify-content: center;
		 -ms-flex-pack: center;
		 justify-content: center;
	}
	 .rf-original-box .author-links-block.linkedin {
		 background-position: 50% 35%;
	}
	 .rf-original-box .blog-main-image {
		 margin-top: -90px;
		 margin-right: -5px;
		 margin-left: -5px;
	}
	 .rf-original-box .author-bottom-wrapper {
		 display: none;
	}
	 .rf-original-box .author-bottom-wrapper.phone {
		 display: block;
	}
	 .rf-original-box .image-14 {
		 margin-right: 10px;
		 margin-bottom: 10px;
	}
	 .rf-original-box .author-container__content {
		 margin-left: 0px;
	}
	 .rf-original-box .blog-written-by-text {
		 margin-bottom: 10px;
	}
	 .rf-original-box .blog-post-header {
		 font-size: 25px;
	}
	 .rf-original-box .author-top-line {
		 width: 80%;
	}
	 .rf-original-box .rich-text h1 {
		 font-size: 30px;
	}
	 .rf-original-box .rich-text h2 {
		 font-size: 25px;
	}
	 .rf-original-box .rich-text h3 {
		 font-size: 20px;
	}
	 .rf-original-box .rich-text h4 {
		 font-size: 18px;
	}
	 .rf-original-box .rich-text h5 {
		 font-size: 16px;
	}
	 .rf-original-box .rich-text h6 {
		 font-size: 14px;
	}
	 .rf-original-box .rich-text figure {
		 margin-top: 30px;
		 margin-right: 0px;
		 margin-bottom: 30px;
		 padding-right: 10px;
		 padding-left: 10px;
	}
	 .rf-original-box .rich-text ul {
		 padding-left: 30px;
	}
	 .rf-original-box .rich-text ol {
		 padding-left: 30px;
	}
	 .rf-original-box .blog-section__blog-link {
		 padding: 15px;
	}
	 .rf-original-box .blog-section__blog-link.ipad {
		 padding-right: 20px;
		 padding-left: 20px;
	}
	 .rf-original-box .fs-section {
		 padding-right: 0px;
		 padding-left: 0px;
	}
	 .rf-original-box .fs-slider {
		 height: auto;
	}
	 .rf-original-box .partner-logos {
		 width: 100%;
		 min-width: auto;
		 grid-column-gap: 30px;
		 grid-row-gap: 50px;
		 -ms-grid-columns: 1fr;
		 grid-template-columns: 1fr;
		 -ms-grid-rows: auto auto auto auto auto auto auto;
		 grid-template-rows: auto auto auto auto auto auto auto;
		 text-align: center;
	}
	 .rf-original-box .partner-logo {
		 width: 100px;
		 -webkit-box-pack: center;
		 -webkit-justify-content: center;
		 -ms-flex-pack: center;
		 justify-content: center;
	}
	 .rf-original-box .partner-logo.logo--monks-hill {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--insignia {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--the-lo {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--amasia {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--nordstar {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--crossbeam {
		 width: 200px;
	}
	 .rf-original-box .partner-logo.logo--january-capital {
		 width: 200px;
	}
	 .rf-original-box .image-17 {
		 text-align: left;
	}
	 .rf-original-box .footer__btns-wrap {
		 width: auto;
		 min-height: 100px;
		 -webkit-box-orient: vertical;
		 -webkit-box-direction: normal;
		 -webkit-flex-direction: column;
		 -ms-flex-direction: column;
		 flex-direction: column;
		 -webkit-box-align: start;
		 -webkit-align-items: flex-start;
		 -ms-flex-align: start;
		 align-items: flex-start;
	}
	 .rf-original-box .partner__slider {
		 width: 90vw;
	}
	 .rf-original-box .partners__mask {
		 width: 120px;
	}
	 .rf-original-box .partners__slide {
		 height: 140px;
	}
	 .rf-original-box .valuation__heading1 {
		 margin-top: 60px;
		 font-size: 30px;
		 line-height: 40px;
	}
	 .rf-original-box .valuation__heading2 {
		 font-size: 19px;
		 line-height: 30px;
	}
	 .rf-original-box .valuation__logo-name {
		 margin-top: 10px;
		 margin-bottom: 10px;
	}
}
 .rf-original-box #w-node-_87b6f296-b810-153b-9365-da0d6a948f32-524dd215 {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-a7b699ba-0f4f-71ea-866a-b969c2f26e30-524dd215 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-e1c2c3c4-27f9-9780-c996-525cba20780f-524dd215 {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-_212d7613-d9d3-c3d1-d471-2c40cecb97e0-524dd215 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-b54328b9-9cf5-e450-b285-f26313774d48-524dd215 {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-d37ae0a2-044d-2298-c418-d3cc98a108e5-524dd215 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-_196abc0c-33fe-7b84-93bb-b3c9615d15ac-615d15a4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-aaf2d81f-6db8-6c7d-b790-f128117e6035-ad4dd21a {
	 -ms-grid-row: 1;
	 -ms-grid-column: 1;
	 -ms-grid-column-span: 3;
	 -ms-grid-column-align: center;
	 justify-self: center;
	 grid-area: Area-3;
}
 .rf-original-box #w-node-f963d310-c6ba-f474-4ba6-9623baa13e0c-ad4dd21a {
	 -ms-grid-row: 1;
	 -ms-grid-column: 5;
	 -ms-grid-column-span: 3;
	 grid-area: Area-4;
}
 .rf-original-box #w-node-c2d723bc-49c6-776a-5d3b-0e73090bc7ab-ad4dd21a {
	 -ms-grid-row: 1;
	 -ms-grid-column: 9;
	 -ms-grid-column-span: 3;
	 grid-area: Area-5;
}
 .rf-original-box #w-node-e7ed5f92-55f6-32c0-1fbf-f86e98e2ad21-ad4dd21a {
	 -ms-grid-row: 3;
	 -ms-grid-column: 3;
	 -ms-grid-column-span: 3;
	 grid-area: Area;
}
 .rf-original-box #w-node-_773eba51-8053-cf65-b982-7f2f0ddec554-ad4dd21a {
	 -ms-grid-row: 3;
	 -ms-grid-column: 7;
	 -ms-grid-column-span: 3;
	 grid-area: Area-2;
}
 .rf-original-box #w-node-aaf2d81f-6db8-6c7d-b790-f128117e6035-694dd21c {
	 -ms-grid-row: 1;
	 -ms-grid-column: 1;
	 -ms-grid-column-span: 3;
	 -ms-grid-column-align: center;
	 justify-self: center;
	 grid-area: Area-3;
}
 .rf-original-box #w-node-f963d310-c6ba-f474-4ba6-9623baa13e0c-694dd21c {
	 -ms-grid-row: 1;
	 -ms-grid-column: 5;
	 -ms-grid-column-span: 3;
	 grid-area: Area-4;
}
 .rf-original-box #w-node-c2d723bc-49c6-776a-5d3b-0e73090bc7ab-694dd21c {
	 -ms-grid-row: 1;
	 -ms-grid-column: 9;
	 -ms-grid-column-span: 3;
	 grid-area: Area-5;
}
 .rf-original-box #w-node-e7ed5f92-55f6-32c0-1fbf-f86e98e2ad21-694dd21c {
	 -ms-grid-row: 3;
	 -ms-grid-column: 3;
	 -ms-grid-column-span: 3;
	 grid-area: Area;
}
 .rf-original-box #w-node-_773eba51-8053-cf65-b982-7f2f0ddec554-694dd21c {
	 -ms-grid-row: 3;
	 -ms-grid-column: 7;
	 -ms-grid-column-span: 3;
	 grid-area: Area-2;
}
 @media screen and (max-width: 767px) {
	 .rf-original-box #w-node-aaf2d81f-6db8-6c7d-b790-f128117e6035-ad4dd21a {
		 -ms-grid-row: 1;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-f963d310-c6ba-f474-4ba6-9623baa13e0c-ad4dd21a {
		 -ms-grid-row: 2;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-c2d723bc-49c6-776a-5d3b-0e73090bc7ab-ad4dd21a {
		 -ms-grid-row: 3;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-e7ed5f92-55f6-32c0-1fbf-f86e98e2ad21-ad4dd21a {
		 -ms-grid-row: 4;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-_773eba51-8053-cf65-b982-7f2f0ddec554-ad4dd21a {
		 -ms-grid-row: 5;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-aaf2d81f-6db8-6c7d-b790-f128117e6035-694dd21c {
		 -ms-grid-row: 1;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-f963d310-c6ba-f474-4ba6-9623baa13e0c-694dd21c {
		 -ms-grid-row: 2;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-c2d723bc-49c6-776a-5d3b-0e73090bc7ab-694dd21c {
		 -ms-grid-row: 3;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-e7ed5f92-55f6-32c0-1fbf-f86e98e2ad21-694dd21c {
		 -ms-grid-row: 4;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
	 .rf-original-box #w-node-_773eba51-8053-cf65-b982-7f2f0ddec554-694dd21c {
		 -ms-grid-row: 5;
		 -ms-grid-column: 1;
		 -ms-grid-column-span: 1;
	}
}
 .rf-original-box #w-node-_87b6f296-b810-153b-9365-da0d6a948f32-884dd21d {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-a7b699ba-0f4f-71ea-866a-b969c2f26e30-884dd21d {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-e1c2c3c4-27f9-9780-c996-525cba20780f-884dd21d {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-_212d7613-d9d3-c3d1-d471-2c40cecb97e0-884dd21d {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-b54328b9-9cf5-e450-b285-f26313774d48-884dd21d {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-d37ae0a2-044d-2298-c418-d3cc98a108e5-884dd21d {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-_454a1860-586b-a9aa-a07f-1eb41b6b605e-1b6b6056 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-_058de5c9-5595-19f0-75ee-eeb1a3f9d67d-1153aca4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-_058de5c9-5595-19f0-75ee-eeb1a3f9d681-1153aca4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-_058de5c9-5595-19f0-75ee-eeb1a3f9d686-1153aca4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-_058de5c9-5595-19f0-75ee-eeb1a3f9d68a-1153aca4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 .rf-original-box #w-node-_058de5c9-5595-19f0-75ee-eeb1a3f9d68f-1153aca4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
	 -webkit-align-self: center;
	 -ms-flex-item-align: center;
	 -ms-grid-row-align: center;
	 align-self: center;
}
 .rf-original-box #w-node-_058de5c9-5595-19f0-75ee-eeb1a3f9d693-1153aca4 {
	 -ms-grid-column-align: center;
	 justify-self: center;
}
 @media screen and (max-width: 991px) {
	 .rf-original-box #w-node-_05b36dc5-c80d-276a-2edf-1d804b69020f-524dd215 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-eba9fa55-a01f-7677-240e-1dd295904216-524dd215 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-eba9fa55-a01f-7677-240e-1dd295904229-524dd215 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-eba9fa55-a01f-7677-240e-1dd29590423c-524dd215 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-eba9fa55-a01f-7677-240e-1dd295904256-524dd215 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_14c5a384-7876-fade-4666-8499a691ded5-884dd21d {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_94d95fba-a5cc-b551-8c67-19a40362019e-884dd21d {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_94d95fba-a5cc-b551-8c67-19a4036201b1-884dd21d {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_94d95fba-a5cc-b551-8c67-19a4036201c4-884dd21d {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_94d95fba-a5cc-b551-8c67-19a4036201de-884dd21d {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-ce0ee0b4-56de-db78-5765-1cad985ffc75-5b4dd221 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-ab176d1d-3e14-e092-b659-fc12e6d013eb-5b4dd221 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_58243013-6d88-7bfc-bd07-96be73a1d554-5b4dd221 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_1da5f193-8290-a12a-86d1-5a5f4e08a0f3-5b4dd221 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_28f4ad0e-f1fa-45c3-6622-2df8909f1513-5b4dd221 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-aa2ec0f4-2b2b-f382-2a6d-ed715f3ab903-bf4dd222 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_8d45ed92-62c3-ee87-0dfc-1c46c5d90506-bf4dd222 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-fc297816-3fbd-d970-1c47-6769b0f02155-bf4dd222 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_92ae0972-a95f-759c-6e2e-d1410e8bebd2-bf4dd222 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
	 .rf-original-box #w-node-_8d45ed92-62c3-ee87-0dfc-1c46c5d90524-bf4dd222 {
		 -ms-grid-row: span 1;
		 grid-row-start: span 1;
		 -ms-grid-row-span: 1;
		 grid-row-end: span 1;
		 -ms-grid-column: span 2;
		 grid-column-start: span 2;
		 -ms-grid-column-span: 2;
		 grid-column-end: span 2;
	}
}
 @media screen and (max-width: 767px) {
	 .rf-original-box #w-node-a627fa9c-77e6-3e4f-9762-b63400c50823-ad4dd21a {
		 -ms-grid-column-align: center;
		 justify-self: center;
	}
	 .rf-original-box #w-node-f674666b-25b9-c5b3-4b13-6c887861f02d-ad4dd21a {
		 -ms-grid-column-align: center;
		 justify-self: center;
	}
	 .rf-original-box #w-node-a627fa9c-77e6-3e4f-9762-b63400c50823-694dd21c {
		 -ms-grid-column-align: center;
		 justify-self: center;
	}
	 .rf-original-box #w-node-f674666b-25b9-c5b3-4b13-6c887861f02d-694dd21c {
		 -ms-grid-column-align: center;
		 justify-self: center;
	}
}
 